import React from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import label from '../../message/Label'

const { htmlToText } = require('html-to-text');


/*= (message, okCallback, cancelCallback) =>*/
export default class InspectionGuideModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.model = this.props.model?this.props.model:{}
        state.showing = this.props.showing
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                model : props.model?props.model:{},
                comment:props.comment,
                showing:props.showing,
            })
        }
    }


    render(){
        let {showing, model} = this.state
        return (
            /*xl-lg-sm*/
            <Modal
                autoFocus={false}
                size="xl"
                centered={true}
                isOpen={showing}
                backdrop={true}
                zIndex="1">
                {super.render()}
                <ModalHeader>
                    {label.InspectionInstruction}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="created"><strong>{label.Description}</strong></Label>
                                <Input
                                    type="textarea"
                                    name="created"
                                    value={model.description}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="message"><strong>{label.Scope}</strong></Label>
                                <Card>
                                    <CardBody>
                                        <div style={{paddingLeft:'16px', paddingRight:'16px'}} dangerouslySetInnerHTML={{__html: model.scope}} />
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="message"><strong>{label.ReferenceDocument}</strong></Label>
                                <Card>
                                    <CardBody>
                                        <div style={{paddingLeft:'16px', paddingRight:'16px'}} dangerouslySetInnerHTML={{__html: model.referenceDocument}} />
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="message"><strong>{label.TestingEquipmentAndAccessories}</strong></Label>
                                <Card>
                                    <CardBody>
                                        <div style={{paddingLeft:'16px', paddingRight:'16px'}} dangerouslySetInnerHTML={{__html: model.testingEquipmentAndAccessories}} />
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={e=>{
                            e.preventDefault()
                            this.props.onClose()
                        }}>
                        {label.OkIUnderstand}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

InspectionGuideModal.propTypes = {
    model:PropTypes.object.isRequired,
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
}
