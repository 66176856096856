import {BASE_URL, COPYRIGHT, DEBUG} from '../Variable';

export let SINGLE_ITEM="single_item"
export let JOIN_ITEM="join_item"
export let ANY_ITEM="any_item"

export let SPECIFIC = "specific"
export let ANY = "any"


export let PERCENTAGE_POINT = "percentage_point";
export let FIX_POINT = "fix_point";

export let FREE_SHIPPING = "free_shipping"
export let SHIPPING_DISCOUNT = "shipping_discount"
export let PURCHASE_DISCOUNT = "purchase_discount"

export let  _ORDER = 1;
export let  _PAID = 2;
export let  _PACKAGING = 3;
export let  _DELIVERY = 4;
export let  _DONE = 5;
export let  _REJECTED = 6;
export let  _ON_RETURN = 7;
export let  _NOT_PASS_RETURN = 8;

export let _TAKEN = 1
export let _PASS = 2
export let _NOT_PASS = 3
export let _POSTPONED = 4
export let _CLOSED = 5
export let _SUSPENDED = 6
export let _OPEN = 7

export let CURRENCY_SYMBOL = 'Rp'

export let DD_MM_YYYY_HH_MM_SS = 'dd-mm-yyyy HH:MM:ss'
export let DD_MM_YYYY = 'dd-mm-yyyy'
export let DD_MMMM_YYYY = 'dd-mmmm-yyyy'
export let DD_MMMM_YYYY_HH_MM_SS = 'dd-mmmm-yyyy HH:MM:ss'

export let ITEM_POINT = "item_point";
export let PURCHASE_POINT = "purchase_point";

export let MIME_PNG = "image/x-png"
export let MIME_PNG_JPEG = "image/x-png,image/jpeg"
export let MIME_JPEG = "image/jpeg"
export let MIME_ICON = "image/x-icon"
export let MIME_TEXT_HTML = "text/html"

export let FORM_IMAGE_MAX_FILE_SIZE = 20000
export let CATEGORY_IMAGE_MAX_FILE_SIZE = 200
export let MAIN_MODEL_ICON_MAX_FILE_SIZE = 200
export let PRODUCT_IMAGE_MAX_FILE_SIZE = 200
export let BRAND_IMAGE_MAX_FILE_SIZE = 200
export let SELEBGRAM_IMAGE_MAX_FILE_SIZE = 100
export let BANNER_IMAGE_MAX_FILE_SIZE = 500
export let DISCUSSION_IMAGE_MAX_FILE_SIZE = 300
export let MAIN_PRODUCT_MAX_FILE_SIZE = 200
export let ONLINE_SHOP_MAX_FILE_SIZE = 100
export let PRINT_DATA = "PRINT_DATA"
export let REGULAR_ID = 1
export let PRODUCT_KEUANGAN_ID = 2

export let WEBPAGE = "Webpage"
export let APPLICATION = "Application"

export let INSPECTION_STATUS_COLOR = {
    "ALL":"#e56ecb",
    "Taken":"#f3b519",
    "Pass":"#6983FB",
    "Not Pass":"#FF9803",
    "Postponed":"#FF4181",
    "Open":"#108989",
    "Closed":"#0d6c10",
    "Suspended":"#FF0839",
}

export let SC_UNAUTHORIZED = 401

export let PREVIEW_TYPES = [
    {
        id:1,
        name:WEBPAGE
    },
    {
        id:2,
        name:APPLICATION
    }
]
export function previewTypeMap(name) {
    let previewType = undefined
    PREVIEW_TYPES.forEach(value => {
        if(value.name===name){
            previewType = value
        }
    })
    return previewType
}

export let PERIOD_TYPES = [
    {
        id:1,
        name:"NEVER_END"
    },
    {
        id:2,
        name:"PERIODIC"
    }
]

export let INSPECTION_LIMIT_TYPES = [
    {
        id:1,
        name:"NO_LIMIT"
    },
    {
        id:2,
        name:"LIMITED"
    }
]
// export let WORKER_VERIFICATION_TYPES = [
//     {
//         id:1,
//         key:"MUST_VERIFIED",
//         name:"MUST_VERIFIED"
//     },
//     {
//         id:2,
//         key:"NO_NEED_VERIFIED",
//         name:"NO_NEED_VERIFIED"
//     }
// ]

export let FORM_TYPES = [
    {
        id:1,
        name:"GROUP_FORM"
    },
    {
        id:2,
        name:"PAGE_LINK"
    }
]
export let GROUP_FORM_ID = 1
export let PAGE_LINK_ID = 2

export let BRAND_TYPES = [
    {
        id:1,
        name:"PERSONAL"
    },
    {
        id:2,
        name:"CORPORATE"
    }
]

export let BRAND_STATUSES = [
    {
        id:1,
        name:"PUBLISHED"
    },
    // {
    //     id:2,
    //     name:"BANNED"
    // },
    // {
    //     id:3,
    //     name:"SUBJECT_OF_VIOLATION"
    // },
    {
        id:4,
        name:"UNPUBLISH"
    }
]

// export let GENDERS = [
//     {
//         id:1,
//         name:"MALE"
//     },
//     {
//         id:2,
//         name:"FEMALE"
//     }
// ]

export let USER_STATUSES = [
    {
        id:1,
        name:"ACTIVE"
    },
    {
        id:2,
        name:"BLOCKED"
    }
]

export let NEED_VERIFIED = {
    id : 3,
    name : "NEED_VERIFIED"
}

export let VERIFICATION_STATUSES = [
    {
        id:1,
        name:"VERIFIED"
    },
    {
        id:2,
        name:"NOT_VERIFIED"
    },
    NEED_VERIFIED
]


export let NOTIFICATION_TOPICS = [
    {
        id:1,
        name:"NEWS"
    },
    {
        id:2,
        name:"ARTICLE"
    },
    {
        id:3,
        name:"BROADCAST"
    },
    {
        id:4,
        name:"SPECIFIC"
    }
]

export let WITHDRAWAL_STATUSES = [
    {
        id:1,
        name:"Waiting"
    },
    {
        id:2,
        name:"Accepted"
    },
    {
        id:3,
        name:"Issued"
    },
    {
        id:4,
        name:"Rejected"
    },
    {
        id:5,
        name:"Postponed"
    }
]

export let ACTION_VERIFICATION_STATUSES = [
    {
        id:1,
        name:"VERIFIED"
    },
    {
        id:2,
        name:"NOT_VERIFIED"
    },
    {
        id:3,
        name:"NEED_VERIFIED"
    },
    {
        id:4,
        name:"VERIFICATION_REJECTED"
    }
]

export let FORCE_TO_UPDATES = [
    {
        id:1,
        name:true
    },
    {
        id:2,
        name:false
    }
]


export let MODEL_TYPES = ["REFERRAL_AGENT_MODEL","REGULAR_MODEL"]


export default {
    CONFIGURATION: 'id.co.abacus.inspector.user.CONFIGURATION#'+COPYRIGHT,
    AUTHORIZATION: btoa('id.co.abacus.inspector.user.AUTHORIZATION#'+COPYRIGHT),
    USER: 'id.co.abacus.inspector.user.USER#'+COPYRIGHT,

    DEV_LOGIN_USERNAME :"",
    DEV_LOGIN_PASSWORD :"",
    // DEV_LOGIN_USERNAME :"",
    // DEV_LOGIN_PASSWORD :"",
    COPYRIGHT:COPYRIGHT,
    BASE_URL: BASE_URL,
    DEBUG : DEBUG,
    COLOR: {
        ORANGE: '#C50',
        DARKBLUE: '#0F3274',
        LIGHTBLUE: '#6EA8DA',
        DARKGRAY: '#999',
    },

    LIMIT_PAGING_BUTTON : 5,
    LOGGED_IN: 'LOGGED_IN',
    LANGUAGE:'LANGUAGE',
    DEFAULT_LANGUAGE:'in',

    CONFIRM_DG_ACTION_YES : 1,
    CONFIRM_DG_ACTION_NO : 2,

    PRODUCT_TYPE_MULTIGUNA : 1,
    PRODUCT_TYPE_KREDIT : 2,
    PRODUCT_TYPE_ASURANSI: 3,

    MESSAGE_DG_TYPE_WARNING : 1,
    MESSAGE_DG_TYPE_SUCCESS : 2,
    MESSAGE_DG_TYPE_ERROR   : 3,
    HEADER_USERID : "customerId",
    DD_MM_YYYY:"dd-MM-yyyy",
    DD_MM_YYYY_HH_MM_SS:"dd-MM-yyyy hh:mm:ss",
    DD_MM_YYYY_HH_MM_SS_2:"dd/MM/yyyy hh:mm:ss",

    ALL_INVENTORY:"ALL_INVENTORY",
    _ALL_INVENTORY:"All Inventory",
    ACTIVE:"ACTIVE",
    EXPIRED:"EXPIRED",
    ALL:"ALL",
    INACTIVE:"INACTIVE",
    _ALL_TIME : "all_time",
    _DYNAMIC : "dynamic",
    _STATIC : "static",
    ALL_TIME : "All Time",
    DYNAMIC : "Dynamic",
    STATIC : "Static",

    FREE_SHIPPING : "Free Shipping",
    SHIPPING_DISCOUNT : "Shipping Discount",
    PURCHASE_DISCOUNT : "Purchase Discount",



    _ITEM_POINT : "item_point",
    _PURCHASE_POINT : "purchase_point",

    ITEM_POINT : "Item Point",
    PURCHASE_POINT : "Purchase Point",


    _PERCENTAGE_DISCOUNT : "percentage_discount",
    _PRICE_DISCOUNT : "price_discount",

    PERCENTAGE_DISCOUNT : "Percentage Discount",
    PRICE_DISCOUNT : "Price Discount",

    REFERENCE_LENGTH: 10,



    API :{
        LOGIN : "/sign/authEmail",
        LOGOUT : "/signout",
        MODEL_CATEGORIES : "/modelCategories",
        MODEL_CATEGORY : "/modelCategory",
        MODEL_CATEGORY_SAVE : "/modelCategory/save",
        MODEL_CATEGORY_DELETE : "/modelCategory/delete",

        MAIN_MODELS : "/mainModels",
        MAIN_MODEL : "/mainModel",
        MAIN_MODEL_SAVE_UPLOAD : "/mainModel/saveUpload",
        MAIN_MODEL_DELETE : "/mainModel/delete",

        MANUFACTURING_SITES : "/manufacturingSites",
        MANUFACTURING_SITE : "/manufacturingSite",
        MANUFACTURING_SITE_SAVE : "/manufacturingSite/save",
        MANUFACTURING_SITE_DELETE : "/manufacturingSite/delete",


        AGENT_FEES : "/agentFees",
        AGENT_FEE : "/agentFee",
        AGENT_FEE_SAVE : "/agentFee/save",
        AGENT_FEE_DELETE : "/agentFee/delete",

        PRODUCT_AGENTS : "/productAgents",

        DISCUSSION_CATEGORIES : "/discussionCategories",
        DISCUSSION_CATEGORY : "/discussionCategory",
        DISCUSSION_CATEGORY_SAVE: "/discussionCategory/save",
        DISCUSSION_CATEGORY_DELETE : "/discussionCategory/delete",

        DISCUSSIONS : "/discussions",
        DISCUSSION : "/discussion",
        DISCUSSION_SAVE_UPLOAD: "/discussion/saveUpload",
        DISCUSSION_DELETE : "/discussion/delete",

        COMMENTS : "/comments",
        COMMENT : "/comment",
        COMMENT_SAVE : "/comment/save",
        COMMENT_DELETE : "/comment/delete",

        COMMENT_REPLIES : "/commentReplies",
        COMMENT_REPLY : "/commentReply",
        COMMENT_REPLY_SAVE : "/commentReply/save",
        COMMENT_REPLY_DELETE : "/commentReply/delete",

        MULTI_FINANCES : "/multiFinances",
        MULTI_FINANCE : "/multiFinance",
        MULTI_FINANCE_SAVE : "/multiFinance/save",
        MULTI_FINANCE_DELETE : "/multiFinance/delete",

        INSTALLMENT_PERIODS : "/installmentPeriods",
        INSTALLMENT_PERIOD : "/installmentPeriod",
        INSTALLMENT_PERIOD_SAVE : "/installmentPeriod/save",
        INSTALLMENT_PERIOD_DELETE : "/installmentPeriod/delete",

        GROUP_FORMS : "/groupForms",
        GROUP_FORM : "/groupForm",
        GROUP_FORM_SAVE : "/groupForm/save",
        GROUP_FORM_DELETE : "/groupForm/delete",
        CLONE_GROUP_FORM : "/groupForm/clone",

        MODELS : "/models",
        MODEL : "/model",
        MODEL_VALID_TO_APPLY : "/model/validToApply",
        MODEL_VALIDATE_SLUG : "/model/validate",
        MODEL_SAVE : "/model/save",
        MODEL_SAVE_UPLOAD : "/model/saveUpload",
        MODEL_DELETE : "/model/delete",
        MODELS_PUBLISHED : "/models/published",


        REGISTRATIONS : "/registrations",

        USER_DEVICES : "/userDevices",
        USER_DEVICE : "/userDevice",
        USER_DEVICE_SAVE : "/userDevice/save",
        USER_DEVICE_SAVE_UPLOAD : "/userDevice/saveUpload",
        USER_DEVICE_DELETE : "/userDevice/delete",


        HEADINGS : "/headings",
        HEADING : "/heading",
        HEADING_SAVE : "/heading/save",
        HEADING_DELETE : "/heading/delete",

        CONFIGURATIONS : "/configurations",
        CONFIGURATION : "/configuration",
        CONFIGURATION_SAVE : "/configuration/save",
        CONFIGURATION_DELETE : "/configuration/delete",
        CONFIGURATION_ACTIVATE : "/configuration/activate",

        APPLICATION_VERSIONS : "/applicationVersions",
        APPLICATION_VERSION : "/applicationVersion",
        APPLICATION_VERSION_SAVE : "/applicationVersion/save",
        APPLICATION_VERSION_DELETE : "/applicationVersion/delete",


        FAQS : "/faqs",
        FAQ : "/faq",
        FAQ_DELETE : "/faq/delete",
        FAQ_SAVE : "/faq/save",
        FAQ_SAVE_UPLOAD : "/faq/saveUpload",
        FAQ_UPLOAD_IMAGE : "/faq/uploadImage",

        PRODUCTS_DROPDOWN : "/products/dropdown",
        PRODUCTS : "/products",
        PRODUCT : "/product",
        PRODUCT_DELETE : "/product/delete",
        PRODUCT_SAVE : "/product/save",
        PRODUCT_SAVE_UPLOAD : "/product/saveUpload",
        PRODUCT_UPLOAD_IMAGE : "/product/uploadImage",
        PRODUCT_LATEST : "/product/latests",
        PRODUCT_UPDATE_LATEST : "/product/updateLatest",
        PRODUCT_DELETE_LATEST : "/product/deleteLatest",

        PRODUCT_POPULARS : "/product/populars",
        PRODUCT_UPDATE_POPULAR : "/product/updatePopular",
        PRODUCT_DELETE_POPULAR : "/product/deletePopular",


        ALL_INVENTORIES : "/inventory/allInventories",
        INVENTORIES : "/inventories",
        INVENTORY : "/inventory",
        INVENTORY_DELETE : "/inventory/delete",
        INVENTORY_SAVE : "/inventory/save",
        INVENTORY_UPLOAD_IMAGE : "/inventory/uploadImage",
        INVENTORY_DELETE_IMAGE : "/inventory/deleteImage",
        INVENTORY_SAVE_UPLOAD : "/inventory/saveUpload",
        INVENTORY_UPDATE_STOCK : "/inventory/updateStock",

        BANNERS:"/banners",
        BANNER:"/banner",
        BANNER_SAVE_UPLOAD:"/banner/saveUpload",
        BANNER_DELETE:"/banner/delete",

        MAIN_PRODUCTS:"/mainProducts",
        MAIN_PRODUCT:"/mainProduct",
        MAIN_PRODUCT_SAVE_UPLOAD:"/mainProduct/saveUpload",
        MAIN_PRODUCT_DELETE:"/mainProduct/delete",

        USER_RESEND_PASSWORD : "/user/resendPassword",
        USER_CHANGE_PASSWORD : "/user/changePassword",

        USERS : "/users",
        USER_STATUSES : "/userStatuses",
        USER : "/user",
        USER_DELETE : "/user/delete",
        USER_SAVE : "/user/save",
        USER_SAVE_UPLOAD : "/user/saveUpload",
        USER_UPLOAD_IMAGE : "/user/uploadImage",
        USER_VALIDATE_EMAIL : "/user/validateEmail",
        USER_FIRSTNAMES : "/user/firstnames",
        USER_ACTIVITIES : "/userActivities",
        USER_ACTIVITY_TYPES : "/userActivity/types",
        USER_BY_AGENT_CODE : "/user/byUserCode",
        USER_GENERATE_AGENT_CODE : "/user/generateUserCode",
        USERS_NO_FILTER : "/users/nofilter",
        USER_EMAILS : "/user/emails",
        USER_UPDATE_VERIFICATION : "/user/updateVerification",


        ROLES : "/roles",
        ROLE : "/role",
        ROLE_SAVE : "/role/save",
        ROLE_DELETE : "/role/delete",
        ROLE_UPDATE_MENU : "/role/updateMenu",

        USER_ROLES : "/userRoles",
        USER_ROLE : "/userRole",
        USER_ROLE_SAVE : "/userRole/save",
        USER_ROLE_DELETE : "/userRole/delete",
        USER_ROLE_UPDATE_USER_MENU : "/userRole/updateUserMenu",

        GROUP_MENUS : "/groupMenus",
        GROUP_MENU : "/groupMenu",
        GROUP_MENU_SAVE : "/groupMenu/save",
        GROUP_MENU_DELETE : "/groupMenu/delete",

        MENUS : "/menus",
        MENU : "/menu",
        MENU_SAVE : "/menu/save",
        MENU_DELETE : "/menu/delete",
        MENUS_TREE : "/menus/tree",

        USER_MENUS : "/userMenus",
        USER_MENU : "/userMenu",
        USER_MENU_SAVE : "/userMenu/save",
        USER_MENU_DELETE : "/userMenu/delete",
        USER_MENUS_TREE : "/userMenus/tree",

        STOCK_AUDITS : "/stockAudits",

        BANKS : "/banks",
        BANK : "/bank",
        BANK_DELETE : "/bank/delete",
        BANK_SAVE : "/bank/save",
        BANK_SAVE_UPLOAD : "/bank/saveUpload",
        BANK_UPLOAD_IMAGE : "/bank/uploadImage",

        BRANDS : "/brands",
        BRAND : "/brand",
        BRAND_DELETE : "/brand/delete",
        BRAND_SAVE : "/brand/save",
        BRAND_SAVE_UPLOAD : "/brand/saveUpload",
        BRAND_UPLOAD_IMAGE : "/brand/uploadImage",

        ONLINE_SHOPS : "/onlineShops",
        ONLINE_SHOP : "/onlineShop",
        ONLINE_SHOP_DELETE : "/onlineShop/delete",
        ONLINE_SHOP_SAVE : "/onlineShop/save",
        ONLINE_SHOP_SAVE_UPLOAD : "/onlineShop/saveUpload",
        ONLINE_SHOP_UPLOAD_IMAGE : "/onlineShop/uploadImage",


        COURIERS : "/couriers",
        COURIER : "/courier",
        COURIER_DELETE : "/courier/delete",
        COURIER_SAVE : "/courier/save",
        COURIER_SAVE_UPLOAD : "/courier/saveUpload",
        COURIER_UPLOAD_IMAGE : "/courier/uploadImage",
        COURIER_WAYBILL : "/courier/waybill",


        BANK_ACCOUNTS : "/bankAccounts",
        BANK_ACCOUNT : "/bankAccount",
        BANK_ACCOUNT_DELETE : "/bankAccount/delete",
        BANK_ACCOUNT_SAVE : "/bankAccount/save",

        MIDTRANS_MEDIATORS : "/midtransMediators",
        MIDTRANS_MEDIATOR : "/midtransMediator",
        MIDTRANS_MEDIATOR_DELETE : "/midtransMediator/delete",
        MIDTRANS_MEDIATOR_SAVE : "/midtransMediator/save",
        MIDTRANS_MEDIATOR_SAVE_UPLOAD : "/midtransMediator/saveUpload",
        MIDTRANS_MEDIATOR_UPLOAD_IMAGE : "/midtransMediator/uploadImage",


        CUSTOMERS : "/customers",
        CUSTOMERS_NO_FILTER : "/customers/nofilter",
        CUSTOMER : "/customer",
        CUSTOMER_DELETE : "/customer/delete",
        CUSTOMER_SAVE : "/customer/save",
        CUSTOMER_SAVE_UPLOAD : "/customer/saveUpload",
        CUSTOMER_UPLOAD_IMAGE : "/customer/uploadImage",
        CUSTOMER_STATUSES : "/customerStatuses",
        CUSTOMER_FIRSTNAMES : "/customer/firstnames",
        CUSTOMER_EMAILS : "/customer/emails",

        GENDERS:"/genders",

        PROVINCES:"/provinces",
        PROVINCE : "/category",
        PROVINCE_SAVE : "/category/save",
        PROVINCE_DELETE : "/category/delete",

        PAYMENT_MEDIAS:"/paymentMedias",
        PAYMENT_MEDIA : "/paymentMedia",


        CITIES:"/cities",
        CITY : "/city",
        CITY_SAVE : "/city/save",
        CITY_DELETE : "/city/delete",

        DISTRICTS:"/districts",
        DISTRICT : "/district",
        DISTRICT_SAVE : "/district/save",
        DISTRICT_DELETE : "/district/delete",

        VILLAGES:"/villages",
        VILLAGE : "/village",
        VILLAGE_SAVE : "/village/save",
        VILLAGE_DELETE : "/village/delete",

        INSPECTION:"/inspection",
        INSPECTION_CREATE:"/inspection/create",
        INSPECTION_STATUSES:"/inspectionStatuses",
        INSPECTION_STATUS_SUBMIT_OPTIONS:"/inspectionStatuses/submitOptions",
        INSPECTIONS:"/inspections",
        INSPECTION_SERIAL_NUMBERS:"/inspection/serialNumbers",
        INSPECTIONS_ONGOING:"/inspections/ongoing",
        INSPECTIONS_IN_SEARCH:"/inspectionsInSearch",
        INSPECTION_UPDATE_STATUS:"/inspection/changeStatus",
        GET_MY_INSPECTION:"/inspection/getMyInspection",
        VALIDATE_NEW_REFERENCE:"/inspection/validateNewReference",
        INSPECTION_COMMIT:"/inspection/commit",
        INSPECTION_SAVE:"/inspection/save",
        INSPECTION_SAVE_SERiAL_COMPONENT:"/inspection/saveSerialComponent",

        INSPECTION_EVENT:"/inspectionEvent",
        INSPECTION_EVENTS:"/inspectionEvents",
        INSPECTION_EVENTS_LATEST:"/inspectionEvents/latest",
        INSPECTION_EVENT_UPDATE_CUSTOMER_INFO:"/inspectionEvent/updateCustomerInfo",
        CURRENT_AND_PREVIOUS_INSPECTION_EVENTS:"/inspectionEvents/currentAndPrevious",

        RECTIFIER_CONTROLLER_SERIAL_NUMBERS:"/rectifierControllerSerialNumbers",


        WITHDRAWAL_REQUEST:"/withdrawalRequest",
        WITHDRAWAL_STATUSES:"/withdrawalStatuses",
        WITHDRAWAL_REQUESTS:"/withdrawalRequests",
        WITHDRAWAL_REQUESTS_ONGOING:"/withdrawalRequest/ongoing",
        WITHDRAWAL_REQUESTS_IN_SEARCH:"/withdrawalRequestsInSearch",
        WITHDRAWAL_REQUEST_UPDATE_STATUS:"/withdrawalRequest/changeStatus",

        BALANCE_MUTATIONS : "/balanceMutations",
        INJECT_BALANCE : "/injectBalance",


        FLASHSALES : "/flashSales",
        FLASHSALE : "/flashSale",
        FLASHSALE_DELETE : "/flashSale/delete",
        FLASHSALE_SAVE : "/flashSale/save",
        FLASHSALE_CONTENT_SAVE : "/flashSaleContent/save",
        FLASHSALE_ACTIVATE : "/flashSale/activate",

        PRODUCT_BY_FLASHSALE : "/flashSale/products",
        PRODUCT_IN_FLASHSALE_CHECK : "productInFlashSaleCheck",
        FLASHSALE_CONTENT_BY_FLASHSALE:"flashSaleContentByFlashSale",
        FLASHSALE_PRODUCT_DELETE : "/flashSaleProduct/delete",

        SELEBGRAMS:"/selebgrams",
        SELEBGRAM:"/selebgramr",
        SELEBGRAM_SAVE_UPLOAD:"/selebgram/saveUpload",
        SELEBGRAM_DELETE:"/selebgram/delete",

        COLORS : "/colors",
        COLOR : "/color",
        COLOR_SAVE : "/color/save",
        COLOR_DELETE : "/color/delete",

        SIZES : "/sizes",
        SIZE : "/size",
        SIZE_SAVE : "/size/save",
        SIZE_DELETE : "/size/delete",


        DISCOUNTS : "/discounts",
        DISCOUNT : "/discount",
        DISCOUNT_DELETE : "/discount/delete",
        DISCOUNT_SAVE : "/discount/save",
        DISCOUNT_INVENTORY : "/discounts/byProduct",

        VOUCHERS : "/vouchers",
        VOUCHER : "/voucher",
        VOUCHER_DELETE : "/voucher/delete",
        VOUCHER_SAVE : "/voucher/save",
        VOUCHER_ACTIVATE : "/voucher/activate",
        VOUCHER_GENERATE_CODE : "/voucher/generateCode",

        POINTS : "/points",
        POINT : "/point",
        POINT_DELETE : "/point/delete",
        POINT_SAVE : "/point/save",
        POINT_ACTIVATE : "/point/activate",

        VOUCHER_ITEMS : "/voucherItems",
        VOUCHER_ITEM : "/voucherItem",
        VOUCHER_ITEM_UPDATE : "/voucherItem/update",
        VOUCHER_ITEM_UPDATE_MIN_QUANTITY : "/voucherItem/updateMinimumQuantity",
        VOUCHER_ITEM_DELETE : "/voucherItem/delete",
        VOUCHER_ITEM_SAVE : "/voucherItem/save",

        VOUCHER_CUSTOMERS : "/voucherCustomers",
        VOUCHER_CUSTOMER_DELETE : "/voucherCustomer/delete",
        VOUCHER_CUSTOMER_UPDATE : "/voucherCustomer/update",


        POINT_ITEMS : "/pointItems",
        POINT_ITEM : "/pointItem",
        POINT_ITEM_UPDATE : "/pointItem/update",
        POINT_ITEM_UPDATE_MIN_QUANTITY : "/pointItem/updateMinimumQuantity",
        POINT_ITEM_DELETE : "/pointItem/delete",
        POINT_ITEM_SAVE : "/pointItem/save",

        POINT_PRODUCTS : "/point/products",

        VOUCHER_ITEM_INVENTORIES : "/voucherItemInventories",
        VOUCHER_ITEM_INVENTORY : "/voucherItemInventory",
        VOUCHER_ITEM_INVENTORY_VALIDATE_EMPTY : "/voucherItemInventory/validateEmpty",
        VOUCHER_ITEM_INVENTORY_UPDATE : "/voucherItemInventory/update",
        VOUCHER_ITEM_INVENTORY_DELETE : "/voucherItemInventory/delete",
        VOUCHER_ITEM_INVENTORY_SAVE : "/voucherItemInventory/save",

        POINT_ITEM_INVENTORIES : "/pointItemInventories",
        POINT_ITEM_INVENTORY : "/pointItemInventory",
        POINT_ITEM_INVENTORY_VALIDATE_EMPTY : "/pointItemInventory/validateEmpty",
        POINT_ITEM_INVENTORY_UPDATE : "/pointItemInventory/update",
        POINT_ITEM_INVENTORY_DELETE : "/pointItemInventory/delete",
        POINT_ITEM_INVENTORY_SAVE : "/pointItemInventory/save",

        PRODUCT_MERGE_VOUCHER_ITEMS : "/voucherItem/productMergeByVoucherItems",

        POINT_UNREGISTERED_PRODUCT : "/point/unregisteredProducts",
        POINT_UNREGISTERED_INVENTORIES : "/point/unregisteredInventories",

        POINT_REGISTER_INVENTORY : "/point/registerInventory",
        POINT_UNREGISTER_INVENTORY : "/point/unregisterInventory",

        PRODUCT_WITH_EXIST_DISCOUNT : '/discount/allProduct',

        SELEBGRAM_PRODUCTS:"/selebgram/products",
        SELEBGRAM_ENDORSE:"/selebgram/endorse",
        SELEBGRAM_ENDORSEMENT_DELETE:"/selebgram/endorsement/delete",

        CONFIGURATION_CURRENT_ACTIVE:"/configuration/currentActive",

        USER_DASHBOARD:'/user/dashboard',
        DASHBOARD_CATEGORY_ANALYTIC:'/user/dashboard/categoryAnalytic',
        DASHBOARD_TRANSACTION_ITEM_ANALYTIC:'/user/dashboard/transactionItemAnalytic',
        DASHBOARD_TRANSACTION_ANALYTIC:'/user/dashboard/transactionAnalytic',

        SEND_EMAILS:'/sendEmails',
        SEND_EMAIL:'/sendEmail/send',
        SUBSCRIBERS:'/subscribers',
        SUBSCRIBER_EMAILS:'/subscriber/emails',
        SUBSCRIBER_DELETE:'/subscriber/delete',

        NOTIFICATIONS:'/notifications',
        NOTIFICATION:'/notification',
        NOTIFICATION_SEND:'/notification/send',
        NOTIFICATION_USERS:'/notificationUser/byNotification',

        LISTEN_TRANSACTION : "/listen/transaction",
        LISTEN_UPDATE_MODEL : "/listen/updateModel",
        LISTEN_UPDATE_TOKEN : "/listen/updateToken",
        LISTEN_UPDATE_INSPECTIONS : "/listen/updateInspections",
        LISTEN_UPDATE_INSPECTION_EVENTS : "/listen/updateInspectionEvents",
        EMAIL_UPLOAD_IMAGE : "/email/image/uploadImage",
        CART_CUSTOMERS:"/customerInventoryCart/customers",
        CART_BY_CUSTOMER:"/customerInventoryCart/byCustomer",
        CART_REMOVE_ALL:"/customerInventoryCart/removeAll",
        CART_REMOVE_ALL_BY_CUSTOMER:"/customerInventoryCart/removeAllByCustomer",
        CART_REMOVE_SINGLE_ITEM:"/custrInventoryCart/deleteSingleItem",

        INSPECTION_PDF_REPORT : BASE_URL+'/pdf/inspection/report/user',
        BATCH_PRINTS : "/batchPrints",
        BATCH_PRINT : "/batchPrint",
        BATCH_PRINT_SAVE : "/batchPrint/save",
        CSV_UPLOAD_SHOW_SERIAL_NUMBER : "/upload/showSerialNumbers",
        ENTRY_SERIAL_NUMBER : "/entrySerialNumber",
        GET_LATEST_TOKEN : "/token/latest",
        REFRESH_TOKEN : "/token/refresh",
        PRINT_SERIAL_NUMBER : "/template/serialNumber/generate",
        VALIDATE_SERIAL_NUMBER_RANGE : "/serialNumber/validate/range",
        INSPECTION_SAVE_FORM_APPLICATION_IMAGE : "/inspection/saveFormApplicationImage",
        INSPECTION_SAVE_SUB_FORM_APPLICATION_IMAGE : "/inspection/saveSubFormApplicationImage",

    },
    VOUCHER_BENEFIT_TYPE_OPTIONS:[
        {id:FREE_SHIPPING,name:"Free Shipping"},
        {id:SHIPPING_DISCOUNT,name:"Shipping Discount"},
        {id:PURCHASE_DISCOUNT,name:"Purchase Discount"}]
    ,


    VOUCHER_AGGREGATE_OPTIONS_ANY:[
        {
            id:ANY_ITEM,
            name:"Any Item"
        }
    ],

    VOUCHER_AGGREGATE_OPTIONS:[
        {
            id:SINGLE_ITEM,
            name:"Single Item"
        },
        {
            id:JOIN_ITEM,
            name:"Join Item"
        },
        {
            id:ANY_ITEM,
            name:"Any Item"
        }
    ],
    VOUCHER_OWNER_TYPES:[
        {
            id:SPECIFIC,
            name:"Specific"
        },
        {
            id:ANY,
            name:"Any"
        }
    ],
    POINT_AGGREGATE_OPTIONS:[
        {
            id:SINGLE_ITEM,
            name:"Single Item"
        },
        {
            id:JOIN_ITEM,
            name:"Join Item"
        }
    ],
    POINT_AGGREGATE_OPTIONS_2:[
        {
            id:ANY_ITEM,
            name:"Any Item"
        }
    ],
    POINT_TYPE_OPTIONS:[
        {
            id:PERCENTAGE_POINT,
            name:"Percentage"
        },
        {
            id:FIX_POINT,
            name:"Fixed"
        }
    ],


}
