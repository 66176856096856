import React from 'react';
import Typography from '../../components/Typography';
import PropTypes from 'prop-types';

export default class ValidatorLabel extends React.Component{

    state = {

    }

    constructor(props) {
        super(props);
        let state = this.state
        state.message = this.props.message
        this.setState(state)
    }
    componentWillReceiveProps(props, nextContext) {
        let state = this.state
        state.message = props.message
        this.setState(state)
    }

    render() {
        return (
            <Typography tag="h6" className="text-danger">
                <strong style={{fontSize:'12px'}}>
                    {
                        this.state.message
                    }
                </strong>
            </Typography>
        );
    }
}

ValidatorLabel.propTypes = {
    message:PropTypes.string
}
