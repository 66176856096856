import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import BaseComponent from '../BaseComponent';
import label from '../../message/Label'

export default class ImageCropper extends BaseComponent {

  constructor(props) {
    super(props);
    let state = this.state
    state.src =  this.props.src
    state.crop = {
      unit: "%",
      width: 30,
      aspect: this.props.freeTransform?null:this.props.aspect?this.props.aspect:1 / 1
    }
    state.modalShow = this.props.show
    this.setState(state)
  }

  componentWillReceiveProps(props, nextContext) {
    if(props!=this.props){
      this.setState({
        src:props.src,
        modalShow:props.show,
        crop:{
          unit: "%",
          width: 30,
            aspect: this.props.freeTransform?null:this.props.aspect?this.props.aspect:1 / 1
        }
      })
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
          this.imageRef,
          crop,
          "newFile.png"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // const scaleX = image.width / image.width;
    // const scaleY = image.height / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL('image/png', 1.0);
      resolve(dataURL)
      // canvas.toBlob(blob => {
      //   if (!blob) {
      //     //reject(new Error('Canvas is empty'));
      //     console.error("Canvas is empty");
      //     return;
      //   }
      //   blob.name = fileName;
      //   window.URL.revokeObjectURL(this.fileUrl);
      //   this.fileUrl = window.URL.createObjectURL(blob);
      //   resolve(this.fileUrl);
      // }, "image/jpeg", 1);
    });
  }

  openCropperModal=()=>{
    this.setState({
      modalShow:true
    })
  }

  closeCropperModal=()=>{
    this.setState({
      modalShow:false
    })
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
        <Modal
            isOpen={this.state.modalShow}
            centered={true}
            backdrop={false}
            style={{minWidth:(this.imageRef?this.imageRef.naturalWidth+"px":"0px"), minHeight:(this.imageRef?this.imageRef.naturalHeight+"px":"0px")}}>
          {super.render()}
          <ModalHeader>Crop Image</ModalHeader>
          <ModalBody style={{margin:'0 auto'}}>
            {src && (
                <ReactCrop
                    style={{margin:'0 auto'}}
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={e=>{
              e.preventDefault()
              if(this.props.callback){
                this.props.callback(croppedImageUrl)
              }
            }}>
              {label.Ok}
            </Button>{' '}
            <Button color="secondary" onClick={e=>{
              e.preventDefault()
                if(this.props.cancelCallback){
                    this.props.cancelCallback()
                }
              this.closeCropperModal()
            }}>
              {label.Cancel}
            </Button>
          </ModalFooter>
        </Modal>
    );
  }
}

ImageCropper.propTypes = {
     src:PropTypes.any,
     show:PropTypes.bool,
     callback:PropTypes.func,
     cancelCallback:PropTypes.func,
     aspect:PropTypes.number,
     freeTransform:PropTypes.bool,

}
