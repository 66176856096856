import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import PropTypes from 'prop-types';


export default class ItemOption extends React.Component{

    hmap = new Object()

    constructor(props) {
        super(props);
        this.state = {
            callback : this.props.callback,
            objects:this.props.objects,
            default:this.props.default,
            disable:this.props.disable,
            title:this.props.title
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props!=nextProps){
            this.setState({
                callback : nextProps.callback,
                objects:nextProps.objects,
                default:nextProps.default,
                disable:nextProps.disable,
                title:nextProps.title
            }, () => {
                this.state.objects.map((item, index)=>{
                    this.hmap[item.id] = item;
                })
            })
        }
    }
    componentDidMount() {
        this.state.objects.map((item, index)=>{
            this.hmap[item.id] = item;
        })
    }

    render() {
        const objects = this.state.objects!=null?this.state.objects:[];
        const defaultId = this.state.default
        const disabled = this.state.disable
        return (
            <FormGroup>
                {
                    !this.props.hideLabel&&(
                        <Label for="select">{this.props.title}</Label>
                    )
                }
                <Input type="select" name="select" value={defaultId!=null?defaultId:""} onChange={
                    e=>{
                        e.preventDefault()
                        if(this.props.callback!=undefined && this.props.objects.length>0 && e.target.value!=undefined){
                            this.props.callback(this.hmap[e.target.value]!=undefined?this.hmap[e.target.value]:{})
                        }
                        this.setState({
                            default:e.target.value
                        })
                    }

                }
                       disabled={disabled}>
                    {
                        !this.props.hideOptionLabel&&(
                            <option value={null}>-- {this.state.title} --</option>
                        )
                    }
                    {
                        objects.map((item, index) =>(
                            <option key={item.id} value={item.id}>
                                {item[this.props.fieldForLabel?this.props.fieldForLabel:'name'].toString()}
                            </option>
                        ))
                    }
                </Input>
            </FormGroup>
        );
    }
}

ItemOption.propTypes ={
    callback : PropTypes.func,
    objects:PropTypes.any,
    default:PropTypes.any,
    disable:PropTypes.bool,
    title:PropTypes.string,
    hideLabel:PropTypes.bool,
    hideOptionLabel:PropTypes.bool,
    fieldForLabel:PropTypes.string

}
