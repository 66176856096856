import BasePage from './BasePage';
import React from 'react';
import Page from '../components/Page';
import {Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Row} from 'reactstrap';
import {isEmpty, parseDate} from '../utils/Utilities';
import Global, {_OPEN, _POSTPONED, DD_MM_YYYY_HH_MM_SS,} from '../utils/Global';
import {Button, ButtonGroup} from 'reactstrap';
import label from '../message/Label'
import {getUser} from "../utils/StorageUtil";
import FormLaneCheckWidget from "../components/Widget/FormLaneCheckWidget";
import {MdArrowDropDown, MdArrowDropUp, MdHelp, FaUser, MdSave, MdCancel, MdPartyMode} from "react-icons/all";
import InspectionGuideModal from "../components/modal/InspectionGuideModal";
import FormRectifierControllerSerialNumber from "../components/Widget/FormRectifierControllerSerialNumber";
import ConfirmInspectionModal from "../components/modal/ConfirmInspectionModal";
import ConfirmInspectionSubmitModal from "../components/modal/ConfirmInspectionSubmitModal";
import ConfirmInspectionCancelModal from "../components/modal/ConfirmInspectionCancelModal";
import CustomerInfoModal from "../components/modal/CustomerInfoModal";
import SerialCodeHasBeenUsedDialog from "../components/modal/SerialCodeHasBeenUsedDialog";
import {inspectionEventsFromNative} from "../utils/MyUtilities";
import InspectionSuccessDialog from "../components/modal/InspectionSuccessDialog";
import TokenInputModal from "../components/modal/TokenInputModal";
import MainDashboardPage from "./MainDashboardPage";
import {BASE_SERVER, COPYRIGHT} from "../Variable";
import SerialComponentInputModal from "../components/modal/SerialComponentInputModal";
var clone = require('safe-clone-deep');

class InspectionFormCheckPage extends BasePage{

    constructor(props) {
        super(props);
        let reference = (this.props.match&&this.props.match.params&&this.props.match.params.reference)
            ?this.props.match.params.reference:""
        if(!reference){
            this.props.history.goBack();
        }
        let state = this.state
        state.inspection = {}
        state.inspectionEvents = []
        state.inspectionStatusSubmitOptions = [{},{}]
        state.reference = reference
        state.stepNumber = 0
        state.expandInfo = true
        state.justOpen = true
        state.customerInfoModal = false ;
        state.inspectionGuideModal = true ;
        state.modeRectifierControllerSerialNumbers = false
        state.confirmInspectionModal = false
        state.confirmInspectionSubmitModal = false
        state.confirmInspectionCancelModal = false
        state.serialCodeHasBeenUsedMessage = "" ;
        state.serialCodeHasBeenUsedModal = false ;
        state.anotherMatchSerialInspection = {}
        state.generateSerialNumber = false
        state.commitInspectionSuccessDialog = false
        state.tokenInputModal = false
        state.serialComponentModal = false
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchInspectionDetail()
        this.fetchInspectionStatusSubmitOptions()
    }

    fetchInspectionDetail =() =>{
        this.get(Global.API.INSPECTION, {
            params :{
                reference : this.state.reference
            }
        }, null, response =>{
            console.log("inspection response",response)
            if(response.code===200){
                let inspection = response.data
                let inspectionEvent = inspection.currentInspectionEvent
                console.log("ie current =",inspectionEvent)
                this.setState({
                    inspection,
                    inspectionEvent,
                    currentInspectionEvent:inspectionEvent,
                }, () => {
                    this.fetchInspectionEvents(inspection.reference)
                })
            }
        }, true, true)
    }

    fetchInspectionEvents = (reference) =>{
        this.get(Global.API.INSPECTION_EVENTS, {
            params :{
                reference: reference,
                rowLimit:2,
            }
        }, null, response =>{
            if(response.code===200){
                // let inspectionEvents = response.data
                let inspectionEvents = inspectionEventsFromNative(response.data)
                console.log("events",inspectionEvents)
                this.setState({
                    inspectionEvents : inspectionEvents,
                    stepNumber:0,
                })
            }
        }, true, true)
    }

    fetchInspectionStatusSubmitOptions = () =>{
        this.get(Global.API.INSPECTION_STATUS_SUBMIT_OPTIONS, null, null, response =>{
            if(response.code===200){
                let inspectionStatusSubmitOptions = response.data
                this.setState({
                    inspectionStatusSubmitOptions:inspectionStatusSubmitOptions
                })
            }
        }, true, true)
    }


    nextPage = () => {
        let {stepNumber, inspectionEvents, modeRectifierControllerSerialNumbers, currentInspectionEvent, generateSerialNumber} = this.state
        // let currentInspectionEvent = inspectionEvents.length>0?inspectionEvents[0]:{}
        let formLaneApplications = currentInspectionEvent.formLaneApplications?currentInspectionEvent.formLaneApplications:[]
        if(modeRectifierControllerSerialNumbers){
            currentInspectionEvent.inspectionStatus = {}
            this.setState({
                confirmInspectionModal:true,
                currentInspectionEvent:currentInspectionEvent
            })
            return;
        }

        let formLaneApplication = formLaneApplications[stepNumber];
        let formApplications = formLaneApplication.formApplications?formLaneApplication.formApplications:[]
        let failed = false ;
        for (let i = 0; i < formApplications.length ; i++) {
            let formApplication = formApplications[i]
            let form = formApplication.form?formApplication.form:{}
            if(form.mandatory && (isEmpty(formApplication.value) && isEmpty(formApplication.imageUrl))){
                form.error = true ;
                form.message = label.PleaseEnterAFieldValueVariable(form.title)
                failed = true ;
            }
            let subFormApplications = formApplication.subFormApplications?formApplication.subFormApplications:[]
            for (let j = 0; j < subFormApplications.length ; j++) {
                let subFormApplication = subFormApplications[j]
                let form = subFormApplication.form?subFormApplication.form:{}
                if(form.mandatory && (isEmpty(subFormApplication.value) && isEmpty(subFormApplication.imageUrl))){
                    form.error = true ;
                    form.message = label.PleaseEnterAFieldValueVariable(form.title)
                    failed = true ;
                }
            }
        }
        this.setState({inspectionEvents:inspectionEvents})
        if(failed){
            return;
        }
        if(stepNumber>=formLaneApplications.length-1){
            this.setState({
                modeRectifierControllerSerialNumbers:true
            })
            return
        }
        stepNumber = stepNumber+1;
        this.setState({
            stepNumber : stepNumber,
        })
    }

    confirmPostponeInspection(){
        this.setState({
            confirmInspectionCancelModal:true,
        })

    }


    previousPage = () => {
        let {stepNumber, modeRectifierControllerSerialNumbers} = this.state
        if(modeRectifierControllerSerialNumbers){
            this.setState({
                modeRectifierControllerSerialNumbers:false,
                stepNumber : stepNumber,
            })
            return;
        }
        if(stepNumber<=0){
            return
        }
        stepNumber = stepNumber-1;

        this.setState({
            modeRectifierControllerSerialNumbers:false,
            stepNumber : stepNumber,
        })
    }
    saveInspection=()=>{
        let {inspection} = this.state
        let currentInspectionEvent = this.state.currentInspectionEvent
        if(currentInspectionEvent){
            currentInspectionEvent.inspection = inspection
        }
        this.post(Global.API.INSPECTION_SAVE, clone(currentInspectionEvent), null, (response)=>{
            if(response.code===200){
                this.successToast(label.SuccessSavingInspection);
                this.fetchInspectionDetail()
            }else if(response.code===299){
                this.setState({
                    serialCodeHasBeenUsedModal : true ,
                    serialCodeHasBeenUsedMessage: response.message,
                    anotherMatchSerialInspection : response.data
                })
            }else{
                this.openFailedDialog(label.FailedToSendInspection, response.message, ()=>{})
            }
        }, true, false)
    }

    submitInspection=()=>{
        let {inspection, generateSerialNumber} = this.state
        let currentInspectionEvent = this.state.currentInspectionEvent
        let inspectionStatus = currentInspectionEvent.inspectionStatus
        if(currentInspectionEvent){
            currentInspectionEvent.inspection = inspection
        }

        this.post(Global.API.INSPECTION_COMMIT+"/"+inspectionStatus.id+"?generateSerialNumber="+generateSerialNumber, clone(currentInspectionEvent), null, (response)=>{
            if(response.code===200){
                const inspectionEvent = response.data;
                const inspectionStatus = inspectionEvent.inspectionStatus?inspectionEvent.inspectionStatus:{};
                let inspection = inspectionEvent.inspection
                // if (inspectionStatus.id===_POSTPONED){
                //     this.props.history.push(MainDashboardPage.ENDPOINT);
                // }else{
                //     this.setState({commitInspectionSuccessDialog:true, inspectionEvent, inspection})
                // }
                this.setState({commitInspectionSuccessDialog:true, inspectionEvent:inspectionEvent, inspection:inspection}, () => this.forceUpdate())
                // this.successToast(label.SuccessUpdatingStatus)
                // this.props.history.push("/")
            }else if(response.code===299){
                this.setState({
                    serialCodeHasBeenUsedModal : true ,
                    serialCodeHasBeenUsedMessage: response.message,
                    anotherMatchSerialInspection : response.data
                })
            }else{
                this.openFailedDialog(label.FailedToSendInspection, response.message, ()=>{})
            }
        }, true, false)
    }

    cancelInspection=()=>{
        let {inspection} = this.state
        let currentInspectionEvent = inspection.currentInspectionEvent?inspection.currentInspectionEvent:{}
        if(currentInspectionEvent){
            currentInspectionEvent.inspection = inspection
        }
        this.post(Global.API.INSPECTION_COMMIT+"/"+_POSTPONED, clone(currentInspectionEvent), null, (response)=>{
            if(response.code===200){
                // this.setState({inspectionSuccessDialog:true})
                this.props.history.push(MainDashboardPage.ENDPOINT);
            }else{
                this.openFailedDialog(label.FailedUpdatingStatus, response.message, ()=>{})
            }
        }, true, false)
    }

    saveSerialComponent=()=>{
        let {inspection} = this.state
        let serialComponent = inspection.serialComponent?inspection.serialComponent:{}
        this.post(Global.API.INSPECTION_SAVE_SERiAL_COMPONENT+"?inspectionId="+inspection.id, clone(serialComponent), null, (response)=>{
            if(response.code===200){
                this.successToast(label.SuccessUpdatingData)
                inspection.serialComponent = response.data
                this.setState({serialComponentModal:false, inspection})
            }else{
                this.openFailedDialog(label.FailedUpdatingStatus, response.message, ()=>{})
            }
        }, true, false)
    }



    render() {
        let {inspection, inspectionEvents, stepNumber, expandInfo,inspectionSuccessDialog,
            inspectionGuideModal,inspectionEvent,currentInspectionEvent,commitInspectionSuccessDialog,
            modeRectifierControllerSerialNumbers, confirmInspectionModal,
            inspectionStatusSubmitOptions, confirmInspectionSubmitModal,
            confirmInspectionCancelModal, justOpen, customerInfoModal,
            serialCodeHasBeenUsedModal, anotherMatchSerialInspection,
            serialCodeHasBeenUsedMessage, tokenInputModal, generateSerialNumber, serialComponentModal} = this.state
        let model = inspection.model?inspection.model:{}
        let inspectionStatus = inspection.inspectionStatus?inspection.inspectionStatus:{}
        // let currentInspectionEvent = inspectionEvents.length>0?inspectionEvents[0]:{}
        // let currentInspectionEvent = inspectionEvent
        let rectifierControllerSerialNumbers = currentInspectionEvent&&currentInspectionEvent.rectifierControllerSerialNumbers?currentInspectionEvent.rectifierControllerSerialNumbers:[]
        let formLaneApplications = currentInspectionEvent&&currentInspectionEvent.formLaneApplications?currentInspectionEvent.formLaneApplications:[]

        let previousInspection = inspectionEvents.length>1?inspectionEvents[1]:{}
        let previousUser = previousInspection.user?previousInspection.user:{}
        let previousInspectionStatus = previousInspection.inspectionStatus?previousInspection.inspectionStatus:{}
        let user = getUser()
        console.log("events render",inspectionEvents)
        return (
            <>
                {super.render()}
                <SerialCodeHasBeenUsedDialog
                    showing={serialCodeHasBeenUsedModal}
                    inspection={anotherMatchSerialInspection}
                    title={label.FailedToSendInspection}
                    message={serialCodeHasBeenUsedMessage}
                    okCallback={()=>{
                        this.setState({
                            serialCodeHasBeenUsedModal:false,
                            serialCodeHasBeenUsedMessage:"",
                            anotherMatchSerialInspection:{}
                        })
                    }}/>
                <CustomerInfoModal
                    inspectionEvent={currentInspectionEvent}
                    showing={customerInfoModal}
                    onClose={()=>{
                        this.setState({
                            customerInfoModal:false
                        })
                    }}
                    onSaved={(inspectionEvent) => {
                        this.setState({
                            // currentInspectionEvent:inspectionEvent
                            customerInfoModal:false
                        })
                    }}/>
                <InspectionGuideModal
                    model={model}
                    showing={inspectionGuideModal}
                    onClose={() => {
                        this.setState({
                            inspectionGuideModal:false,
                            customerInfoModal:justOpen?true:false,
                            justOpen:false
                        })
                    }}/>
                <ConfirmInspectionModal
                    show={confirmInspectionModal}
                    inspectionEvent={currentInspectionEvent}
                    inspectionStatusSubmitOptions={inspectionStatusSubmitOptions}
                    onClickSubmit={(generateSerialNumber, inspectionMessage, inspectionStatus) => {
                        // currentInspectionEvent.serialCode = serialNumber
                        currentInspectionEvent.inspectionStatus = inspectionStatus
                        currentInspectionEvent.inspectionNote = inspectionMessage
                        currentInspectionEvent.inspection = inspection
                        this.setState({
                            currentInspectionEvent:currentInspectionEvent,
                            confirmInspectionModal:false,
                            confirmInspectionSubmitModal:true,
                            generateSerialNumber:generateSerialNumber,
                        })
                    }}
                    onClickCancel={() => {
                        this.setState({confirmInspectionModal:false})
                    }}
                />
                <ConfirmInspectionSubmitModal
                    inspectionEvent={currentInspectionEvent}
                    showing={confirmInspectionSubmitModal}
                    generateSerialNumber={generateSerialNumber}
                    okCallback={()=>{
                        this.setState({
                            confirmInspectionSubmitModal:false
                        }, () => {
                            this.submitInspection()
                        })
                    }}
                    cancelCallback={()=>{
                        this.setState({
                            confirmInspectionSubmitModal:false
                        })
                    }}
                />
                <ConfirmInspectionCancelModal
                    inspection={inspection}
                    inspectionEvent={currentInspectionEvent}
                    showing={confirmInspectionCancelModal}
                    okCallback={()=>{
                        this.setState({
                            confirmInspectionCancelModal:false
                        }, () => {
                            this.cancelInspection()
                        })
                    }}
                    cancelCallback={()=>{
                        this.setState({confirmInspectionCancelModal:false})
                    }}
                />
                <InspectionSuccessDialog
                    showing={inspectionSuccessDialog}
                    title={label.Success}
                    message={label.SuccessUpdatingStatus}
                    generateSerialNumber={false}
                    inspection={inspection}
                    okCallback={()=>{
                        this.setState({
                            inspectionSuccessDialog:false
                        },()=>{
                            this.fetchInspectionDetail();
                        })
                    }}
                    goToDashboardCallback={()=>{
                        this.props.history.push("/")
                    }}
                    onClickPrint={()=>{}}
                />
                <InspectionSuccessDialog
                    showing={commitInspectionSuccessDialog}
                    title={label.SuccessSavingInspection}
                    inspection={inspection}
                    message={label.SuccessSavingInspection}
                    generateSerialNumber={generateSerialNumber}
                    okCallback={()=>{
                        this.setState({
                            commitInspectionSuccessDialog:false
                        },()=>{
                            this.fetchInspectionDetail();
                        })
                    }}
                    goToDashboardCallback={()=>{
                        this.props.history.push("/")
                    }}
                    onClickPrint={()=>{
                        if(inspection.printedCount>0){
                            tokenInputModal = true ;
                        }else{
                            tokenInputModal = false ;
                        }
                        this.setState({tokenInputModal}, () => {
                            if(tokenInputModal==false){
                                window.open(BASE_SERVER+Global.API.PRINT_SERIAL_NUMBER
                                    +"?startSerialNumber="+inspection.serialCode+"&endSerialNumber="+inspection.serialCode
                                    +"&userCode="+user.userCode,"_blank")
                            }
                        })
                    }}
                />
                <TokenInputModal
                    showing={tokenInputModal}
                    onClose={()=>{this.setState({tokenInputModal:false})}}
                    onEnterToken={(token)=>{
                        this.setState({tokenInputModal:false}, () => {
                            window.open(BASE_SERVER+Global.API.PRINT_SERIAL_NUMBER
                                +"?startSerialNumber="+inspection.serialCode+"&endSerialNumber="+inspection.serialCode
                                +"&token="+token+"&userCode="+user.userCode,"_blank")
                            })
                    }}/>
                <SerialComponentInputModal
                    inspection={inspection}
                    showing={serialComponentModal}
                    positiveButton={label.Save}
                    negativeButton={label.Close}
                    onClose={()=>{
                        this.setState({
                            serialComponentModal:false,
                        })
                    }}
                    onCreateAssembly={()=>{
                        this.setState({
                            serialComponentModal:false,
                        }, async () => {
                            this.saveSerialComponent()
                        })
                    }}/>
                <Page
                    title={label.InspectionItem+" "+model.systemType}
                    breadcrumbs={[{ name: label.TakingInspectionItem, active: true }]}>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    {label.ModelInformation}
                                    <ButtonGroup style={{marginLeft:'16px'}}>
                                        <Button
                                            outline
                                            className={"button-md-icon"}
                                            onClick={() => {
                                                this.setState({
                                                    expandInfo:!expandInfo
                                                })
                                            }}>
                                            {this.state.expandInfo?
                                                (
                                                    <MdArrowDropUp/>
                                                )
                                                :
                                                (
                                                    <MdArrowDropDown/>
                                                )}
                                        </Button>
                                        &nbsp;
                                        <Button
                                            outline
                                            className="button-md-icon"
                                            color="secondary"
                                            onClick={() => {
                                                this.setState({
                                                    customerInfoModal:true
                                                })
                                            }}>
                                            {label.Customer}&nbsp;<FaUser/>
                                        </Button>
                                        &nbsp;
                                        <Button
                                            outline
                                            className={"button-md-icon"}
                                            color={"info"}
                                            onClick={() => {
                                                this.setState({
                                                    inspectionGuideModal:true
                                                })
                                            }}>
                                            {label.Instruction}&nbsp;<MdHelp/>
                                        </Button>
                                        &nbsp;
                                        <Button
                                            outline
                                            className={"button-md-icon"}
                                            color={"primary"}
                                            onClick={() => {
                                                this.setState({
                                                    serialComponentModal:true
                                                })
                                            }}>
                                            {label.SerialComponent}&nbsp;<MdPartyMode/>
                                        </Button>

                                    </ButtonGroup>
                                </CardHeader>
                                <Collapse isOpen={expandInfo}>
                                    <CardBody>
                                        <Row>
                                            <Col md={3} sm={12}>
                                                    <FormGroup>
                                                        <Label for="name">{label.SystemType}</Label>
                                                        <Input disabled type="text" name="title" value={model.systemType}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3} sm={12}>
                                                        <FormGroup>
                                                            <Label for="name">{label.Reference}</Label>
                                                            <Input disabled type="text" name="title" value={inspection.reference}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3} sm={12}>
                                                            <FormGroup>
                                                                <Label for="name">{label.PerformedBy}</Label>
                                                                <Input disabled type="text" name="title" value={user.fullname}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3} sm={12}>
                                                                <FormGroup>
                                                                    <Label for="name">{label.CurrentInspectionStatus}</Label>
                                                                    <Input disabled type="text" name="title" value={inspectionStatus.label}/>
                                                                </FormGroup>
                                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4} sm={12}>
                                                <FormGroup>
                                                    <Label for="name">{label.PreviousInspectionPerformedBy}</Label>
                                                    <Input disabled type="text" name="title" value={previousUser.fullname?previousUser.fullname:"-"}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} sm={12}>
                                                    <FormGroup>
                                                        <Label for="name">{label.PreviousInspectionStatus}</Label>
                                                        <Input disabled type="text" name="title" value={previousInspectionStatus.label?previousInspectionStatus.label:"-"}/>
                                                    </FormGroup>
                                                </Col>
                                            <Col md={4} sm={12}>
                                                    <FormGroup>
                                                        <Label for="name">{label.PreviousInspectionDate}</Label>
                                                        <Input disabled type="text" name="title" value={previousInspection.created?parseDate(previousInspection.created, DD_MM_YYYY_HH_MM_SS):"-"}/>
                                                    </FormGroup>
                                                </Col>
                                        </Row>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={3} sm={12}>
                                            <Button block color={"danger"} onClick={(e) => {
                                                e.preventDefault()
                                                this.confirmPostponeInspection()
                                            }}>
                                                {label.PostponeInspection}&nbsp;<MdCancel/>
                                            </Button>
                                        </Col>
                                        <Col md={3} sm={12}>
                                            <Button block color={"purple"} onClick={(e) => {
                                                e.preventDefault()
                                                this.saveInspection()
                                            }}>
                                                {label.SaveInspection}&nbsp;<MdSave/>
                                            </Button>
                                        </Col>
                                        <Col md={3} sm={6}>
                                            <Button block color={stepNumber===0?"disabled":"warning"} className={"float-right"} onClick={(e) => {
                                                e.preventDefault()
                                                this.previousPage()
                                            }}>
                                                {label.PagePrevious}
                                            </Button>
                                        </Col>
                                        <Col md={3} sm={6}>
                                            <Button block color={"primary"} className={"float-right"} onClick={(e) => {
                                                e.preventDefault()
                                                this.nextPage()
                                            }}>
                                                {label.PageNext}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {
                                                modeRectifierControllerSerialNumbers?
                                                    (
                                                        <FormRectifierControllerSerialNumber
                                                            inspection={inspectionEvents}
                                                            inspectionEvent={currentInspectionEvent}
                                                            rectifierControllerSerialNumbers={rectifierControllerSerialNumbers}/>
                                                    )
                                                    :
                                                    (
                                                        <FormLaneCheckWidget
                                                            inspection={inspection}
                                                            inspectionEvent={currentInspectionEvent}
                                                            stepFormLaneId={formLaneApplications[stepNumber]?formLaneApplications[stepNumber].laneId:0}
                                                            formLaneApplication={formLaneApplications[stepNumber]?formLaneApplications[stepNumber]:{}}/>
                                                    )
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Page>
            </>
    );
    }
    }

    export default Object.assign(InspectionFormCheckPage, {ENDPOINT : "/inspection/formCheck/:reference"})