import * as React from 'react';
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import inspectionConfirmImage from '../../assets/img/ic_inspection_confirm.png';
import PropTypes from 'prop-types';
import HorizontalLine from "../Widget/HorizontalLine";
import Typography from "../Typography";
import label from "../../message/Label";
import MyCardImage from "../Card/MyCardImage";


export default class ConfirmInspectionSubmitModal extends React.Component{

    constructor(props ){
        super(props);
        this.state = {
            inspectionEvent : this.props.inspectionEvent?this.props.inspectionEvent:{},
            generateSerialNumber:this.props.generateSerialNumber,
        }
    }

    componentWillReceiveProps(props , nextContext) {
        if(this.props.modal!=props.showing){
            this.setState({
                inspectionEvent : props.inspectionEvent?props.inspectionEvent:{},
                generateSerialNumber:props.generateSerialNumber,
            })
        }
    }

    render(){
        let {inspectionEvent, generateSerialNumber} = this.state
        let inspection = inspectionEvent.inspection?inspectionEvent.inspection:{}
        let inspectionStatus = inspectionEvent.inspectionStatus?inspectionEvent.inspectionStatus:{}
        let serialNumberView = generateSerialNumber?"N/A":inspection.serialCode?inspection.serialCode:"N/A";
        return (
            <Modal
                centered={true}
                isOpen={this.props.showing}
                backdrop={true}>
                <ModalHeader>
                    {label.ConfirmSubmission}
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody style={{textAlign:'center'}}>
                            <MyCardImage style={{width:'240px'}} src={inspectionConfirmImage}/>
                        </CardBody>
                    </Card>
                    <br/>
                    <div style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: label.YouWillSubmitInspectionWithReferenceVariable1AndSerialCodeVariable2WithStatusVariable3_AreYouSure(inspection.reference,
                            serialNumberView, inspectionStatus.label)}} />
                    <br/>
                    {
                        inspectionEvent.inspectionNote?
                            (
                                <div style={{textAlign:'center'}}>
                                    <div style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html:label.InspectionNote}}></div>
                                    <Typography className="text-danger"><em>{inspectionEvent.inspectionNote}</em></Typography>
                                </div>
                            )
                            :
                            (null)
                    }
                </ModalBody>
                <HorizontalLine/>
                <ModalBody>
                    <Row>
                        <Col>
                            <Button color="warning" onClick={e=>{
                                this.props.cancelCallback()
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className={"float-right"}
                                color="primary" onClick={e=>{
                                this.props.okCallback()
                            }}>
                                {label.YesIamSure}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}
ConfirmInspectionSubmitModal.propTypes = {
    inspectionEvent:PropTypes.object.isRequired,
    showing:PropTypes.bool.isRequired,
    okCallback:PropTypes.func.isRequired,
    cancelCallback:PropTypes.func.isRequired,
    generateSerialNumber:PropTypes.bool.isRequired,
}
