import React from "react";
import {CardImg} from "reactstrap";
import {canUseWebP} from "../../utils/Utilities";
import LetteredAvatar from "lettered-avatar";

class Image extends React.Component{

    render() {
    let {src} = this.props
        if(src){
            if(src && src.includes("base64")){
                // Base 64 nothing to do
            }else{
                if(canUseWebP()){
                    src = src+"?webp=true"
                }else{
                    src = src+"?webp=false"
                }
            }
        }
    return(
        <img{...this.props} src={src}/>
    )
    }
}

export default Image