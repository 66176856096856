import React from 'react';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import label from '../../message/Label'
import {isEmpty} from "../../utils/Utilities";
import WarningLabel from "../Widget/WarningLabel";

const { htmlToText } = require('html-to-text');

/*= (message, okCallback, cancelCallback) =>*/
export default class SerialComponentInputModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        let inspection = this.props.inspection?this.props.inspection:{}
        inspection = inspection?inspection:{}
        let components = []
        if(!isEmpty(inspection.serialComponent)){
            let serialComponent = inspection.serialComponent?inspection.serialComponent:{}
            components = serialComponent.components?serialComponent.components:[]
        }else{
            let model = inspection.model?inspection.model:{}
            components = model.components?model.components:[]
        }
        state.inspection = inspection
        state.components = components
        state.showing = this.props.showing
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            let inspection = props.inspection?props.inspection:{}
            inspection = inspection?inspection:{}
            let components = []
            if(!isEmpty(inspection.serialComponent)){
                let serialComponent = inspection.serialComponent?inspection.serialComponent:{}
                components = serialComponent.components?serialComponent.components:[]
            }else{
                let model = inspection.model?inspection.model:{}
                components = model.components?model.components:[]
            }

            this.setState({
                inspection : inspection,
                components : components,
                showing:props.showing,
            })
        }
    }

    onCreateAssembly(){
        let {inspection, components} = this.state
        inspection = inspection?inspection:{}
        let valid = true
        components.forEach((value, index) => {
                if(isEmpty(value.value)) {
                    valid = false;
                    components[index].error = true
                }
            })
        let serialComponent = {}
        serialComponent.components = components
        inspection.serialComponent = serialComponent
        this.setState({components})
        if(valid){
            this.props.onCreateAssembly(inspection)
        }

        // if(isEmpty(inspectionEvent.customerOrderNo)){
        //     this.setState({
        //         customerOrderNoWarning:label.EnterCustomerOrderNo
        //     })
        //     return ;
        // }
        // if(isEmpty(inspectionEvent.client)){
        //     this.setState({
        //         clientWarning:label.EnterClient
        //     })
        //     return ;
        // }
        // if(isEmpty(inspectionEvent.productSKU)){
        //     this.setState({
        //         productSKUWarning:label.EnterProductSKU
        //     })
        //     return ;
        // }
        // if(isEmpty(inspectionEvent.productName)){
        //     this.setState({
        //         productNameWarning:label.EnterProductName
        //     })
        //     return ;
        // }
        // this.props.onClose()
        // let form = new FormData()
        // form.append("id", inspectionEvent.id)
        // form.append("customerOrderNo", inspectionEvent.customerOrderNo)
        // form.append("client", inspectionEvent.client)
        // form.append("productSKU", inspectionEvent.productSKU)
        // form.append("productName", inspectionEvent.productName)
        // let response = await this.asyncPost(Global.API.INSPECTION_EVENT_UPDATE_CUSTOMER_INFO, form, null, false, false)
        // console.log("form response",response)
        //
        // if(isSuccess(response)){
        //     this.props.onSaved(response.data)
        // }
    }


    render(){
        let {showing, inspection, components} = this.state
        let model = inspection.model?inspection.model:{}

        return (
            /*xl-lg-sm*/
            <Modal
                autoFocus={false}
                size="xl"
                centered={true}
                isOpen={showing}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.SerialComponentList}
                </ModalHeader>
                <ModalBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.Name}</th>
                            <th>{label.Value}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            components.map((item, index)=>(
                                <>
                                <tr key={item.id} style={{borderBottom:(!isEmpty(item.description)?'hidden':'1px')}}>
                                    <td width={'10%'}>
                                        {(++index)}
                                    </td>
                                    <td width={'30%'}>
                                        {item.label}
                                    </td>
                                    <td width={'60%'}>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={item.value}
                                            onChange={(e) =>{
                                                item.value = e.target.value
                                                item.error = false
                                                this.setState({
                                                    components
                                                })
                                            }}
                                        />
                                        <WarningLabel show={item.error===true} message={label.PleaseEnterValue}/>
                                    </td>
                                </tr>
                                {!isEmpty(item.description)&&(
                                    <tr>
                                        <td colSpan={3} style={{padding:'0rem'}}>
                                        <span style={{fontStyle:'italic', fontSize:'0.85rem', color:'blue'}}>
                                                *{label.Description} : {item.description}
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                </>
                            ))
                        }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="warning"
                        onClick={async (e)=>{
                            e.preventDefault()
                            this.props.onClose()
                        }}>
                        {this.props.negativeButton}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={ (e)=>{
                            e.preventDefault()
                            this.onCreateAssembly()
                        }}>
                        {this.props.positiveButton}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

SerialComponentInputModal.propTypes = {
    inspection:PropTypes.object.isRequired,
    positiveButton:PropTypes.string.isRequired,
    negativeButton:PropTypes.string.isRequired,
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onCreateAssembly:PropTypes.func.isRequired,
}
