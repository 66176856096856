import BasePage from './BasePage';
import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import {changeParam2, changeParam3, isEmpty, navigatePage, parseDate, sortirMap} from '../utils/Utilities';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {IoMdSettings} from 'react-icons/io';
import SearchInput from '../components/SearchInput';
import Global, {_PASS, DD_MM_YYYY_HH_MM_SS, INSPECTION_STATUS_COLOR} from '../utils/Global';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import SockJsClient from 'react-stomp';
import label from '../message/Label'
import {FiArrowDownCircle, FiArrowUpCircle} from "react-icons/fi";
import DateInput from "../components/Widget/DateInput";
import ItemOption from "../components/Widget/ItemOption";
import {MdRestore, MdPrint} from "react-icons/md";
import {FaFileExcel} from "react-icons/fa";
import Page from "../components/Page";
import ModelModal from "../components/modal/ModelModal";
import TokenInputModal from "../components/modal/TokenInputModal";
import {BASE_SERVER} from "../Variable";
import {getUser} from "../utils/StorageUtil";
import BatchPrintInputModal from "../components/modal/BatchPrintInputModal";


class SerialNumberPage extends BasePage{

    constructor(props) {
        super(props);
        this.state ={
            ascending:false,
            sortir:'ja.updated',
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            key:queryString.parse(this.props.query).tab?queryString.parse(this.props.query).tab:label.All,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            updatedStartDate:null,
            updatedEndDate:null,
            inspections:[],
            inspection:{},
            models:[],
            model:{},
            modelModal:false,
            inspectionStatus:null,
            inspectionStatusId:_PASS,
            userFilterExpand:true,
            tokenInputModal:false,
            batchPrintInputModal:false,
            startSerialNumberToPrint:"",
            endSerialNumberToPrint:"",
            inputSearch:queryString.parse(this.props.query).search?queryString.parse(this.props.query).search:"",
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchModels()
        this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
    }

    componentWillReceiveProps(props, nextContext) {
        if (props != this.props) {
            let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
            let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
            let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
            let inspectionStatusId = _PASS
            this.setState({
                page: page,
                key: key,
                inputSearch: inputSearch,
                inspectionStatusId: inspectionStatusId
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
            })
        }
    }

    fetchModels = () =>{
        this.get(Global.API.MODELS, null, null, response=>{
            if(response.code===200){
                this.setState({
                    models : response.data
                })
            }
        }, true, true)
    }

    fetchAll = (ascending, sortir, search, progressing) =>{
        let params = {
            ascending:ascending,
            sortir:sortir,
            search:search,
            page:this.state.page-1,
            createdStartDate:this.state.createdStartDate,
            createdEndDate:this.state.createdEndDate,
            updatedStartDate:this.state.updatedStartDate,
            updatedEndDate:this.state.updatedEndDate,
            modelId:this.state.model.id,
        }
        this.get(Global.API.INSPECTION_SERIAL_NUMBERS, {params:params}, null, res =>{
            if(res.code === 200){
                this.setState({
                    inspections : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    // ['size-' + inspectionStatusId ? inspectionStatusId : "all"]: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, progressing, true);
    }

    refreshInspections = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir,
        }, () => {
            this.fetchAll(ascending, sortir, this.state.inputSearch, progressing)
        })
    )

    resetFilter(){
        this.setState({
            page :1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            updatedStartDate:null,
            updatedEndDate:null,
            inspections:[],
            inspectionStatus:null,
            inspectionStatusId:null,
            model:{},
            inputSearch : "",
        }, () => {
            // navigatePage(this.props, 1)
            changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
        })
    }



    render() {
        let totalElement = this.state.totalElement
        let totalPage = this.state.totalPage
        let {models, model, modelModal, tokenInputModal, inspection, batchPrintInputModal, startSerialNumberToPrint, endSerialNumberToPrint} = this.state
        let user = getUser();
        return (
            <Page
                title={label.InspectionList}
                breadcrumbs={[{ name: label.InspectionList, active: true }]}
                className="TablePage"
            >
                {super.render()}
                <ModelModal
                    show={modelModal}
                    model={model}
                    onClickOk={()=>{
                        this.setState({modelModal:false})
                    }}/>
                <BatchPrintInputModal
                    showing={batchPrintInputModal}
                    onClose={()=>{
                        this.setState({batchPrintInputModal:false})
                    }}
                    onOkBatchPrint={(startSerialNumber, endSerialNumber, errorMap)=>{
                        if(errorMap && errorMap.needToken){
                            this.setState({
                                startSerialNumberToPrint:startSerialNumber,
                                endSerialNumberToPrint:endSerialNumber,
                                tokenInputModal:true,
                            })
                        }else{
                            window.open(BASE_SERVER+Global.API.PRINT_SERIAL_NUMBER
                                +"?startSerialNumber="+startSerialNumber+"&endSerialNumber="+endSerialNumber
                                +"&userCode="+user.userCode,"_blank")
                            window.onfocus = this.setState({tokenInputModal:false, batchPrintInputModal:false, startSerialNumberToPrint:"", endSerialNumberToPrint:""}, () => {
                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, false)
                            })

                        }
                    }}/>
                <TokenInputModal
                    showing={tokenInputModal}
                    onClose={()=>{this.setState({tokenInputModal:false})}}
                    onEnterToken={(token)=>{
                        if(batchPrintInputModal){
                            window.open(BASE_SERVER+Global.API.PRINT_SERIAL_NUMBER
                                +"?startSerialNumber="+startSerialNumberToPrint+"&endSerialNumber="+endSerialNumberToPrint
                                +"&token="+token+"&userCode="+user.userCode,"_blank")
                        }else{
                            window.open(BASE_SERVER+Global.API.PRINT_SERIAL_NUMBER
                                +"?startSerialNumber="+inspection.serialCode+"&endSerialNumber="+inspection.serialCode
                                +"&token="+token+"&userCode="+user.userCode,"_blank")
                        }
                        window.onfocus = this.setState({tokenInputModal:false, batchPrintInputModal:false, startSerialNumberToPrint:"", endSerialNumberToPrint:""}, () => {
                            this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, false)
                        })
                    }}/>

                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader style={{cursor:'pointer'}} onClick={event=>{
                                this.setState({
                                    userFilterExpand:!this.state.userFilterExpand
                                })
                            }}>
                                {
                                    (this.state.userFilterExpand)?(<FiArrowUpCircle/>):((<FiArrowDownCircle/>))
                                }
                                &nbsp;{label.AdvanceFilter} ({label.ClickToExpandFilterContent})
                            </CardHeader>
                            <Collapse isOpen={this.state.userFilterExpand}>
                                <CardHeader>
                                    <Row>
                                        <Col md={3}>
                                            <Label for="createdStartDate">{label.CreatedStartDate}</Label>
                                            <DateInput
                                                id="createdStartDate"
                                                value={this.state.createdStartDate}
                                                maxdate={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdStartDate : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="createdEndDate">{label.CreatedEndDate}</Label>
                                            <DateInput
                                                id="createdEndDate"
                                                mindate={this.state.createdStartDate}
                                                value={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdEndDate :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="updatedStartDate">{label.DataChangesStartDate}</Label>
                                            <DateInput
                                                id="updatedStartDate"
                                                value={this.state.updatedStartDate}
                                                maxdate={this.state.updatedEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        updatedStartDate : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="updatedEndDate">{label.DataChangesEndDate}</Label>
                                            <DateInput
                                                id="updatedEndDate"
                                                mindate={this.state.updatedStartDate}
                                                value={this.state.updatedEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        updatedEndDate :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectModel}
                                                    objects={models}
                                                    default={!isEmpty(model)?model.id:null}
                                                    callback={(model)=>{
                                                        this.setState({
                                                            model:model
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {/*<Col md={3}>*/}
                                        {/*    <Label for="updatedStartDate">{label.PrintingStartDate}</Label>*/}
                                        {/*    <DateInput*/}
                                        {/*        id="updatedStartDate"*/}
                                        {/*        value={this.state.updatedStartDate}*/}
                                        {/*        maxdate={this.state.updatedEndDate}*/}
                                        {/*        onChange={(e) =>{*/}
                                        {/*            let value = e.target.value*/}
                                        {/*            this.setState({*/}
                                        {/*                updatedStartDate : value?value:null*/}
                                        {/*            })*/}
                                        {/*        }}*/}
                                        {/*        placeholder={label.EnterStartDate}*/}
                                        {/*    />*/}
                                        {/*</Col>*/}
                                        {/*<Col md={3}>*/}
                                        {/*    <Label for="updatedEndDate">{label.PrintingEndDate}</Label>*/}
                                        {/*    <DateInput*/}
                                        {/*        id="updatedEndDate"*/}
                                        {/*        mindate={this.state.updatedStartDate}*/}
                                        {/*        value={this.state.updatedEndDate}*/}
                                        {/*        onChange={(e) =>{*/}
                                        {/*            let value = e.target.value*/}
                                        {/*            this.setState({*/}
                                        {/*                updatedEndDate :  value?value:null*/}
                                        {/*            })*/}
                                        {/*        }}*/}
                                        {/*        placeholder={label.EnterEndDate}*/}
                                        {/*    />*/}
                                        {/*</Col>*/}
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalRecord}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'green'}}><strong>{totalElement}</strong></h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalPage}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'blue'}}>{totalPage}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8}>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        {/*<Label>{label.Searching}</Label>*/}
                                                        <SearchInput
                                                            placeholder={label.SearchReferenceSerialModelUser}
                                                            value={this.state.inputSearch}
                                                            onChange={e=>{
                                                                this.setState({
                                                                    inputSearch:e.target.value
                                                                }, () => {
                                                                    if(this.state.inputSearch===''){
                                                                        changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                                    }
                                                                })
                                                            }}
                                                            onEnter={e=>{
                                                                if(this.state.inputSearch!==''){
                                                                    changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                                }
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Button
                                                        block
                                                        size={"lg"}
                                                        className={"float-left"}
                                                        color={"primary"}
                                                            onClick={event => {
                                                                navigatePage(this.props, 1)
                                                            }}>
                                                        <IoMdSettings/>&nbsp;{label.ApplyFilter}
                                                    </Button>
                                                </Col>
                                                <Col md={4}>
                                                    <Button size={"lg"} block className={"float-left"} color={"yellow"}
                                                            onClick={event => {
                                                                this.resetFilter()
                                                            }}><MdRestore/>&nbsp;{label.ResetFilter}</Button>
                                                </Col>
                                                <Col md={4}>
                                                    <Button size={"lg"} block className={"float-left"} color={"black"} outline
                                                            onClick={event => {
                                                                // let serialNumber = in
                                                                // this.resetFilter()
                                                                this.setState({batchPrintInputModal:true})
                                                            }}><MdPrint/>&nbsp;{label.BatchPrint}</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                {this.renderInspection()}
            </Page>
        )
    }

    renderInspection = () =>{
        return (
            <Row key={2}>
                <Col>
                    <Card>
                        <CardHeader>
                            <strong>{label.Inspection}</strong>
                            <br/><br/>
                            <Row>
                                <Col md={3}>
                                    {label.SortBy} :
                                    <UncontrolledButtonDropdown key={1}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                sortirMap(this.state.sortir.toString())
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu style={{transform:'none'}}>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.reference", true))}>{label.Reference}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.serialCode", true))}>{label.SerialCode}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jm.systemType", true))}>{label.SystemType}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.performerName", true))}>{label.PerformedBy}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.created", true))}>{label.Created}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "ja.updated", true))}>{label.Updated}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={3}>
                                    {label.Sortir} :
                                    <UncontrolledButtonDropdown key={2}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                this.state.ascending?label.Ascending:label.Descending
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={e=>(this.refreshInspections(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {this.renderInspectionTable()}
                        </CardBody>
                        <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
                        <SockJsClient
                            url={Global.BASE_URL}
                            topics={[Global.API.LISTEN_UPDATE_INSPECTIONS]}
                            getRetryInterval={5}
                            heartbeat={60000}
                            onMessage={(message) => {
                                this.successToast(message)
                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                            }}
                            ref={ (client) => { this.clientRef = client }} />
                    </Card>
                </Col>
            </Row>
        )
    }

    renderInspectionTable = () =>{
        let {tokenInputModal, inspections} = this.state
        let user = getUser();

        return (
            <Table responsive hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{label.SerialCode}</th>
                    <th>{label.ReferenceCode}</th>
                    <th>{label.Model}</th>
                    <th>{label.Status}</th>
                    <th>{label.CreatedBy}</th>
                    <th>{label.Created}</th>
                    <th>{label.Updated}</th>
                    <th>{label.Printed}</th>
                    <th>{label.Print}</th>
                </tr>
                </thead>
                <tbody>
                {
                    inspections.map((item, index)=>(
                        <tr key={item.id}>
                            <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                            <td><a href={'/inspectionDetail?id='+item.id}>{item.serialCode}</a></td>
                            <td><a href={'/inspectionDetail?id='+item.id}>{item.reference}</a></td>
                            <td>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.setState({
                                            model:item.model,
                                            modelModal:true
                                        })
                                    }}>
                                    {item.model.systemType}
                                </a>
                            </td>
                            <td>
                                {item.inspectionStatus.label}
                            </td>
                            <td>
                                {
                                    item.user?(
                                            <a href={'/userDetail?id='+item.user.id}>{item.performerName}</a>
                                        ):
                                        (<span>{item.performerName}</span>)
                                }
                            </td>
                            <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                            <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                            <td>{item.printedCount}</td>
                            <td>
                                <Button
                                    color={item.id?'danger':'disabled'}
                                    outline
                                    disabled={!item.id}
                                    onClick={()=>{
                                        if(item.printedCount>0){
                                            tokenInputModal = true ;
                                        }else{
                                            tokenInputModal = false ;
                                        }
                                        this.setState({inspection:item, inspections, tokenInputModal}, () => {
                                            if(tokenInputModal==false){
                                                item.printedCount = item.printedCount+1
                                                window.open(BASE_SERVER+Global.API.PRINT_SERIAL_NUMBER
                                                    +"?startSerialNumber="+item.serialCode+"&endSerialNumber="+item.serialCode
                                                    +"&userCode="+user.userCode,"_blank")
                                            }
                                        })
                                    }}
                                >
                                    <MdPrint/>
                                </Button>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        )
    }
}
export default Object.assign(SerialNumberPage, {ENDPOINT : "/serialNumbers"})