import Avatar from '../../components/Avatar';
import {UserCard} from '../../components/Card';
import React from 'react';
import {MdClearAll, MdExitToApp, MdNotificationsActive, MdPersonPin, MdNetworkCheck} from 'react-icons/md';
import {Button, Input, ListGroup, ListGroupItem, Nav, Navbar, NavItem, NavLink, Popover, PopoverBody} from 'reactstrap';
import bn from '../../utils/bemnames';
import {AuthConsumer} from '../../utils/AuthContext';
import userImage from '../../assets/img/users/user_avatar.png';
import {isEmpty, parseDate} from '../../utils/Utilities';
import BaseComponent from '../BaseComponent';
import Global, {DD_MMMM_YYYY} from '../../utils/Global';
import label, {LANGUAGE, LANGUAGES} from '../../message/Label';
import {getData, storeData} from '../../utils/StorageUtil';
import NetworkMeterPage from "../../pages/NetworkMeterPage";


const bem = bn.create('header');

class Header extends BaseComponent {

  constructor(props) {
    super(props);
    let state = this.state
    state.isOpenNotificationPopover = false
    state.isNotificationConfirmed = false
    state.isOpenUserCardPopover = false
    this.setState(state)
  }


  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };


  // parseDate = (dateString) =>{
  //   const date = new Date(dateString)
  //   return new Intl.DateTimeFormat().format(date);
  // }

  render() {
    const { isNotificationConfirmed } = this.state;
    let languageKey = getData(Global.LANGUAGE)
    if(isEmpty(languageKey)){
      languageKey = Global.DEFAULT_LANGUAGE
    }

    return (
        <AuthConsumer>
          {({user, logout})=>(
              <Navbar light expand className={bem.b('bg-white')}>
                {super.render()}
                <Nav navbar className="mr-2">
                  <Button outline onClick={this.handleSidebarControlButton}>
                    <MdClearAll size={25} />
                  </Button>
                </Nav>
                <Nav navbar>
                </Nav>
                <Nav navbar className={"mr-2 "+bem.e('nav-right')}>
                  <NavItem style={{marginRight:'16px'}}>
                    <Input
                        style={{marginTop:'8px'}}
                        type="select"
                        name="select"
                        value={JSON.stringify(LANGUAGE[languageKey])}
                        onChange={e=>{
                          e.preventDefault()
                          let valueGson = e.target.value
                          let language = JSON.parse(valueGson)
                          storeData(Global.LANGUAGE, language.key)
                          window.location.reload()
                        }}>
                      {
                        LANGUAGES.map((item, index) =>(
                            <option
                                key={item.id}
                                value={JSON.stringify(item)}>
                              {item.name}
                            </option>
                        ))
                      }
                    </Input>
                  </NavItem>
                  <NavItem>
                    <NavLink id="Popover2">
                      <Avatar
                          className="can-click"
                          src={user.photoUrl?user.photoUrl:userImage}
                          onClick={(e)=>{
                            e.preventDefault()
                            this.toggleUserCardPopover()
                          }}
                          onError={(elm)=>this.defaultImage(elm, userImage)}
                      />
                    </NavLink>
                    <Popover
                        placement="bottom-end"
                        isOpen={this.state.isOpenUserCardPopover}
                        // toggle={()=>{this.toggleUserCardPopover()}}
                        target="Popover2"
                        className="p-0 border-0"
                        style={{ minWidth: 250 }}>
                      <PopoverBody className="p-0 border-light">
                        <UserCard
                            avatar={user.photoUrl?user.photoUrl:userImage}
                            title={user.firstname+" "+user.lastname}
                            subtitle={user.email}
                            text={label.Updated+" "+parseDate(user.updated, DD_MMMM_YYYY)}
                            className="border-dark"
                            onClickAvatar={()=>{

                            }}
                        >
                          <ListGroup flush>
                            <ListGroupItem tag="button" action className="border-light" onClick={event=>{
                              event.preventDefault()
                              this.setState({
                                isOpenUserCardPopover:false
                              }, () => {
                                this.props.history.push("/profile")
                              })
                            }}>
                              <MdPersonPin /> {label.Profile}
                            </ListGroupItem>

                            <ListGroupItem tag="button" action className="border-light" onClick={event=>{
                              event.preventDefault()
                              this.setState({
                                isOpenUserCardPopover:false
                              }, () => {
                                this.props.history.push(NetworkMeterPage.ENDPOINT)
                              })
                            }}>
                              <MdNetworkCheck /> {label.NetworkMeter}
                            </ListGroupItem>

                            {/*<ListGroupItem tag="button" action className="border-light">*/}
                            {/*  <a href="https://abacus-eguide.numeralasia.com" target="_blank"  onClick={event=>{*/}
                            {/*    this.setState({*/}
                            {/*      isOpenUserCardPopover:false*/}
                            {/*    })*/}
                            {/*  }}>*/}
                            {/*    <MdHelp /> {label.Help}*/}
                            {/*  </a>*/}
                            {/*</ListGroupItem>*/}
                            <ListGroupItem tag="button" action className="border-light"  onClick={event=>{
                              event.preventDefault()
                              this.setState({
                                isOpenUserCardPopover:false
                              }, () => {
                                this.openConfirmDialog(label.Confirmation, label.DoYouWantToLogoutFormThiApplication, async ()=>{
                                  let response = await this.asyncGet(Global.API.LOGOUT, null, null,  true, false)
                                  logout()
                                }, ()=>{})
                              })
                            }}>
                              <MdExitToApp/> {label.Logout}
                            </ListGroupItem>
                          </ListGroup>
                        </UserCard>
                      </PopoverBody>
                    </Popover>
                  </NavItem>
                </Nav>
              </Navbar>
          )
          }
        </AuthConsumer>
    );
  }
}

export default Header;
