import React from 'react';
import Modal from 'react-awesome-modal';
import PropTypes from 'prop-types';
import {Progress} from 'reactstrap';
import Typography from "../Typography";

export default class PercentProgressBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      percentage: 0,
      show: this.props.show,
      message:this.props.message
    }
  }
  componentWillReceiveProps(props, nextContext) {
      if(this.props!=props){
          this.setState({
              show: props.show,
              percentage:props.percentage,
              message:this.props.message
          })
      }
  }

  render() {
    return (
        <Modal visible={this.state.show} width="350px" height="15px" effect="fadeInDown" onClickAway={() => {}}>
            <Progress
                color={"primary"}
                animated
                value={this.state.percentage}
                className="mb-3"
            />
            <Typography
                style={{color:'white', textAlign:'center', display:'block'}}
                type="info">{this.state.message}</Typography>
        </Modal>
    )
  }
}

// const Filler = (props) => {
//   return <div className="filler" style={{ width: `${props.percentage}%` }} />
// }

PercentProgressBar.propTypes = {
    show: PropTypes.bool,
    percentage:PropTypes.number,
    message:PropTypes.string
};
