import * as React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import label from '../../message/Label'
import failedImage from '../../assets/img/widget/failed.png';
import * as PropTypes from "prop-types";
import MyCardImage from "../Card/MyCardImage";
import {parseDate} from "../../utils/Utilities";
import {DD_MM_YYYY_HH_MM_SS} from "../../utils/Global";

export default class SerialCodeHasBeenUsedDialog extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            title : this.props.title,
            message : this.props.message,
            inspection:this.props.inspection
        }

    }

    componentWillReceiveProps(props , nextContext) {
        if(this.props!=props && props.showing){
            this.setState({
                title : props.title,
                message : props.message,
                inspection : props.inspection
            })
        }
    }

    render(){
        let {inspection} = this.state
        return (
            <Modal
                centered={true}
                isOpen={this.props.showing}
                backdrop={true}>
                <ModalHeader>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody style={{textAlign:'center'}}>
                                    <MyCardImage style={{width:'60%'}} src={failedImage}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                    <Col style={{textAlign:'center', fontSize:'22px'}}>
                        {this.props.message}
                    </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>{label.SameSerialCodeInformation}</CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            {label.Reference}
                                        </Col>
                                        <Col md={1}>:</Col>
                                        <Col>
                                            {inspection.reference}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {label.SerialCode}
                                        </Col>
                                        <Col md={1}>:</Col>
                                        <Col>
                                            {inspection.serialCode}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {label.SystemType}
                                        </Col>
                                        <Col md={1}>:</Col>
                                        <Col>
                                            {inspection.model?inspection.model.systemType:""}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {label.PerformedBy}
                                        </Col>
                                        <Col md={1}>:</Col>
                                        <Col>
                                            {inspection.user?inspection.user.fullname:""}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {label.Updated}
                                        </Col>
                                        <Col md={1}>:</Col>
                                        <Col>
                                            {parseDate(inspection.updated, DD_MM_YYYY_HH_MM_SS)}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        block
                        color="primary"
                        onClick={e=>{
                            this.props.okCallback()
                        }}>
                        {label.Ok}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

SerialCodeHasBeenUsedDialog.propTypes = {
    showing:PropTypes.bool.isRequired,
    inspection:PropTypes.object.isRequired,
    title:PropTypes.string.isRequired,
    message:PropTypes.string.isRequired,
    okCallback:PropTypes.func.isRequired,
}
