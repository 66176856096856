import {NumberWidget} from '../components/Widget';
import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {getColor} from '../utils/colors';
import BasePage from './BasePage';
import Global, {_OPEN, _TAKEN} from '../utils/Global';
import {MdRefresh} from 'react-icons/md/index';
import SockJsClient from 'react-stomp';
import {UserDashboard} from '../model/model';
import label from '../message/Label'
import HorizontalLine from "../components/Widget/HorizontalLine";
import FindInspectionModal from "../components/modal/FindInspectionModal";
import CreateInspectionWhenNotFoundConfirmModal from "../components/modal/CreateInspectionWhenNotFoundConfirmModal";
import CreateInspectionConfirmModal from "../components/modal/CreateInspectionConfirmModal";
import InspectionEventDetailModal from "../components/modal/InspectionEventDetailModal";
import ContinueInspectionConfirmModal from "../components/modal/ContinueInspectionConfirmModal";
import SearchInput from "../components/SearchInput";
import TimeAgo from "react-timeago/lib"; // not working on safari
import idStrings from 'react-timeago/lib/language-strings/id'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
// import ReactLoadMore from "react-more-load"
import StatusIndicator from "../components/Widget/StatusIndicator";
import emptyBoxPng from '../assets/img/widget/empty-box.png';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Typography from "../components/Typography";
import MyLetteredAvatar from "../components/Card/MyLetteredAvatar";
import {Image} from "react-bootstrap";
import {
    arrayToObject,
    isFailed,
    isSuccess,
    handleTimeAgo,
    isEmpty,
    handleTimeAgoWithMillisecond
} from "../utils/Utilities";

import dayjs from 'dayjs' // ES 2015
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {inspectionEventsFromNative} from "../utils/MyUtilities";
import SerialComponentInputModal from "../components/modal/SerialComponentInputModal";
import CreateAssemblySuccessModal from "../components/modal/CreateAssemblySuccessModal";
import InfiniteScroll from "react-infinite-scroll-component";

const formatter = buildFormatter(idStrings)

const today = new Date();

let transactionCardRef =  React.createRef()
let categoryCardRef =  React.createRef()
let transactionItemCardRef =  React.createRef()
let transactionAnalyticCardRef =  React.createRef()

var relativeTime = require('dayjs/plugin/relativeTime')
var updateLocale = require('dayjs/plugin/updateLocale')
var clone = require('safe-clone-deep');


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

class MainDashboardPage extends BasePage {

    constructor(props) {
        super(props);
        let state = this.state
        state.mounted = false
        state.mainModels = []
        state.models = []
        state.selectedModel = {}
        state.inspectionEvents = []
        state.inspection = {}
        state.userDashboard = new UserDashboard()
        state.scanModal = false ;
        state.inspectionModal = false ;
        state.createInspectionWhenNotFoundConfirmModal = false ;
        state.createInspectionConfirmModal = false ;
        state.serialComponentModal = false
        state.continueInspectionConfirmModal = false ;
        state.inspectionEventModal = false ;
        state.inspectionEvent = {}
        state.inspectionEventPage = 0
        state.inspectionEventTotalElement = 0
        state.inspectionEventTotalPage = 0
        state.inspectionEventPageElement = 0
        state.fetchInspectionEvents = false ;
        state.inspectionEventSearch = "";
        state.openedInspections = []
        state.createAssemblySuccessModal = false

        this.setState(state)
    }


    componentDidMount() {
        // this is needed, because InfiniteCalendar forces window scroll
        window.scrollTo(0, 0);
        if(!this.state.mounted){
            // this.fetchUserDashboard()
            this.setState({
                mounted : true
            }, () => {
                this.refreshDashboard("")
            })
        }
    }


    fetchMainModels = ()=> {
        this.get(Global.API.MAIN_MODELS, null, null, response=>{
            if(response.code===200){
                this.setState({
                    mainModels:response.data
                })

            }else{
                this.errorToast(response.message)
            }
        }, false, true)
    }

    fetchOpenedInspection(){
        this.get(Global.API.INSPECTIONS, {params:{inspectionStatusId:_OPEN}}, null, (response)=>{
            if(response.code==200){
                let openedInspections = response.data
                this.setState({
                    openedInspections:openedInspections
                })
            }
        }, false, false,)
    }

    async fetchInspectionEvents(){
        let inspectionEventPage = this.state.inspectionEventPage
        let fetchInspectionEvents = this.state.fetchInspectionEvents
        let inspectionEventTotalPage = this.state.inspectionEventTotalPage
        let inspectionEventSearch = this.state.inspectionEventSearch
        if(inspectionEventPage>=inspectionEventTotalPage && inspectionEventTotalPage>0){
            return;
        }
        let params = {page:inspectionEventPage, eventSearch:inspectionEventSearch}
        let response = await this.asyncGet(Global.API.INSPECTION_EVENTS_LATEST, {params}, null,  false, true)
        if(response && response.code===200){
            console.log("response", response.data)
            let responseInspectionEvents = inspectionEventsFromNative(response.data)
            // let responseInspectionEvents = response.data;
            let inspectionEvents = this.state.inspectionEvents
            if(inspectionEvents!=null && inspectionEvents.length>0){
                responseInspectionEvents.forEach((responseInspectionEvent, index)=>{
                    let exist = false ;
                    inspectionEvents.forEach(inspectionEvent => {
                        if(inspectionEvent.id===responseInspectionEvent.id){
                            exist = true ;
                        }
                    })
                    if(!exist){
                        inspectionEvents.push(responseInspectionEvent)
                    }
                })
            }else{
                inspectionEvents = responseInspectionEvents;
            }
            // inspectionEvents.pushAll(response.data)
            let inspectionEventTotalElement = response.totalElement
            let inspectionEventTotalPage = response.totalPage
            let inspectionEventPageElement = response.pageElement

            this.setState({
                inspectionEvents:inspectionEvents,
                inspectionEventTotalElement:inspectionEventTotalElement,
                inspectionEventTotalPage:inspectionEventTotalPage,
                inspectionEventPageElement:inspectionEventPageElement,
                fetchInspectionEvents:false
            })
        }
    }
    fetchModels = ()=> {
        let params = {
            sortir : "jt.suggestion"
        }
        this.get(Global.API.MODELS_PUBLISHED, {params}, null, response=>{
            if(response.code===200){
                this.setState({
                    models:response.data
                })
            }else{
                this.errorToast(response.message)
            }
        }, false, true)
    }

    fetchUserDashboard=(progressing)=>{
        this.get(Global.API.USER_DASHBOARD, null, null, response=>{
            if(response.code===200){
                this.setState({
                    userDashboard:response.data
                })
            }else{
                this.errorToast(response.message)
            }
        }, true, true)
    }

    createInspection = async (inspection) => {
        let response = await this.asyncGet(Global.API.MODEL_VALID_TO_APPLY,
            {params:{modelId:inspection.model.id, reference:inspection.reference}}, null, true, true)
        if(isFailed(response)){
            this.openFailedDialog(label.Failed, response.message)
            return
        }
        response = await this.asyncPost(Global.API.INSPECTION_CREATE, inspection, null, true, true)
        if(isSuccess(response)){
            let inspection = response.data;
            this.setState({
                createAssemblySuccessModal:true,
                inspection
            })
        }else if(isFailed(response)){
            this.errorToast(response.message)
        } else{
            this.errorToast(label.Failed)
        }
    }

    updateInspection = (inspectionEvent) => {
        this.post(Global.API.INSPECTION_COMMIT+"/"+_TAKEN, clone(inspectionEvent), null, response=>{
            if(response.code===200){
                inspectionEvent = response.data;
                this.successToast(response.message)
                this.props.history.push('/inspection/formCheck/'+inspectionEvent.inspection.reference)
            }else{
                this.errorToast(response.message)
            }
        }, true, true)
    }


    refreshDashboard(message){
        this.fetchOpenedInspection()
        this.fetchUserDashboard(true)
        this.fetchMainModels()
        this.fetchModels()
        this.fetchInspectionEvents()

    }

    render() {

        const primaryColor = getColor('primary');
        const secondaryColor = getColor('secondary');
        let {userDashboard, mainModels, models, inspectionEvents,
            scanModal, selectedModel, inspectionModal, inspection,
            createInspectionWhenNotFoundConfirmModal, createInspectionConfirmModal,
            continueInspectionConfirmModal, serialComponentModal, inspectionEventModal, inspectionEvent,
            inspectionEventPage, inspectionEventTotalElement, inspectionEventTotalPage,
            inspectionEventPageElement, fetchInspectionEvents, inspectionEventSearch,
            openedInspections, createAssemblySuccessModal
        } = this.state

        return (
            <>
                {super.render()}
                <FindInspectionModal
                    show={scanModal}
                    model={selectedModel}
                    onScanEntry={(inspection) => {
                        if(!inspection.id){
                            this.setState({
                                inspection:inspection,
                                scanModal:false,
                                createInspectionWhenNotFoundConfirmModal:true,
                            })
                        }else{
                            inspectionEvent = inspection.currentInspectionEvent
                            if(inspectionEvent){
                                inspectionEvent.inspection = inspection
                            }

                            this.setState({
                                inspectionEvent:inspectionEvent,
                                scanModal:false,
                                inspectionEventModal:true,
                            })
                        }
                    }}
                    onClickNew={(inspection) => {
                        this.setState({
                            inspection:inspection,
                            scanModal:false,
                            createInspectionConfirmModal:true,
                        })
                    }}
                    onClickCancel={() => {
                        this.setState({
                            inspection:{},
                            scanModal:false
                        })
                    }}
                />
                <InspectionEventDetailModal
                    show={inspectionEventModal}
                    inspectionEvent={inspectionEvent}
                    onContinueInspection={(inspectionEvent)=>{
                        this.setState({
                            inspectionEvent,
                            continueInspectionConfirmModal:true,
                            inspectionEventModal:false
                        })
                    }}
                    onClose={()=>{
                        this.setState({inspectionEventModal:false})
                    }}
                />
                <CreateInspectionWhenNotFoundConfirmModal
                    show={createInspectionWhenNotFoundConfirmModal}
                    inspection={inspection}
                    onClickOk={ async () => {
                        await this.createInspection(inspection)
                        this.setState({
                            createInspectionWhenNotFoundConfirmModal:false,
                        })
                    }}
                    onClickCancel={() => {
                        this.setState({
                            scanModal:true,
                            createInspectionWhenNotFoundConfirmModal:false,
                        })
                    }}
                />
                <SerialComponentInputModal
                    inspection={inspection}
                    showing={serialComponentModal}
                    positiveButton={label.CreateNewAssembly}
                    negativeButton={label.Cancel}
                    onClose={()=>{
                        this.setState({
                            serialComponentModal:false,
                            inspection:undefined
                        })
                    }}
                    onCreateAssembly={(inspection)=>{
                        this.setState({
                            serialComponentModal:false,
                            inspection:inspection
                        }, async () => {
                            await this.createInspection(inspection)
                        })
                }}/>
                <CreateInspectionConfirmModal
                    show={createInspectionConfirmModal}
                    inspection={inspection}
                    onClickOk={async (reference) => {
                        inspection.reference = reference
                        // await this.createInspection(inspection)
                        this.setState({
                            createInspectionConfirmModal:false,
                            serialComponentModal:true,
                            inspection:inspection
                        })
                    }}
                    onClickCancel={() => {
                        this.setState({
                            scanModal:true,
                            createInspectionConfirmModal:false,
                            serialComponentModal:false,
                            inspection:undefined
                        })
                    }}
                />
                <CreateAssemblySuccessModal
                    show={createAssemblySuccessModal}
                    onClickOk={()=>{
                        this.props.history.push('/inspection/formCheck/'+inspection.reference)
                    }}
                    onClickCancel={()=>{
                        this.setState({
                            createAssemblySuccessModal:false, inspection:undefined}
                            , () => {
                            this.refreshDashboard()
                            })
                    }}
                    inspection={inspection}/>
                <ContinueInspectionConfirmModal
                    show={continueInspectionConfirmModal}
                    inspection={inspectionEvent.inspection}
                    onClickOk={() => {
                        this.setState({
                            continueInspectionConfirmModal:false,
                        }, () => {
                            this.updateInspection(inspectionEvent)
                        })
                    }}
                    onClickCancel={() => {
                        this.setState({
                            inspectionEvent:{},
                            continueInspectionConfirmModal:false,
                        })
                    }}
                />

                <Row style={{minHeight:'125vh'}}>
                    <Col md={9}>
                        <CardBody>
                            <Row>
                                <Col>
                                    <span style={{fontSize:'26px', fontWeight:'bold'}}>{label.Dashboard}</span>
                                </Col>
                                <Col className="float-right">
                                    <Button className="float-right" onClick={event=>{
                                        this.refreshDashboard("")
                                    }}>{label.Refresh} <MdRefresh/></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{textAlign:'center'}}>
                                        <Carousel
                                            autoPlay={true}
                                            infinite={true}
                                            partialVisible={true}
                                            autoPlaySpeed={3000}
                                            style={{maxWidth:'10%'}}
                                            responsive={responsive}
                                            itemClass="carousel-item-padding-40-px">
                                            <NumberWidget
                                                style={{margin:'12px'}}
                                                title={label.TotalInspection}
                                                subtitle={label.PreviousMonth+" : "+userDashboard.previousMonthInspection}
                                                number={userDashboard.totalInspection&&userDashboard.totalInspection.toString()}
                                                color="secondary"
                                                progress={{
                                                    value: Math.ceil(userDashboard.thisMonthInspectionPercent),
                                                    label: label.ThisMonth+' : '+userDashboard.thisMonthInspection,
                                                }}
                                            />
                                            <NumberWidget
                                                style={{margin:'12px'}}
                                                title={label.Taken}
                                                subtitle={label.PreviousMonth+" : "+userDashboard.previousMonthTaken}
                                                number={userDashboard.totalTaken&&userDashboard.totalTaken.toString()}
                                                color="secondary"
                                                progress={{
                                                    value: Math.ceil(userDashboard.thisMonthTakenPercent),
                                                    label: label.ThisMonth+' : '+userDashboard.thisMonthTaken,
                                                }}
                                            />
                                            <NumberWidget
                                                style={{margin:'12px'}}
                                                title={label.PassInspection}
                                                subtitle={label.PreviousMonth+" : "+userDashboard.previousMonthPass}
                                                number={userDashboard.totalPass&&userDashboard.totalPass.toString()}
                                                color="secondary"
                                                progress={{
                                                    value: Math.ceil(userDashboard.thisMonthPassPercent),
                                                    label: label.ThisMonth+' : '+userDashboard.thisMonthPass,
                                                }}
                                            />
                                            <NumberWidget
                                                style={{margin:'12px'}}
                                                title={label.NotPassInspection}
                                                subtitle={label.PreviousMonth+" : "+userDashboard.previousMonthNotPass}
                                                number={userDashboard.totalNotPass&&userDashboard.totalNotPass.toString()}
                                                color="secondary"
                                                progress={{
                                                    value: Math.ceil(userDashboard.thisMonthNotPassPercent),
                                                    label: label.ThisMonth+' : '+userDashboard.thisMonthNotPass,
                                                }}
                                            />
                                            <NumberWidget
                                                style={{margin:'12px'}}
                                                title={label.PostponedInspection}
                                                subtitle={label.PreviousMonth+" : "+userDashboard.previousMonthPostponed}
                                                number={userDashboard.totalPostponed}
                                                color="secondary"
                                                progress={{
                                                    value: Math.ceil(userDashboard.thisMonthPostponedPercent),
                                                    label: label.ThisMonth+' : '+userDashboard.thisMonthPostponed,
                                                }}
                                            />
                                        </Carousel>
                                    </div>
                                </Col>
                            </Row>
                            {
                                openedInspections.length>0&&(
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader>{label.OpenedInspection}</CardHeader>
                                                <CardBody>
                                                    <Carousel
                                                        infinite={true}
                                                        partialVisible={true}
                                                        style={{maxWidth:'10%'}}
                                                        responsive={responsive}
                                                        itemClass="carousel-item-padding-40-px">
                                                        {openedInspections.map((item,index)=>{
                                                            return (
                                                                <Card style={{margin:'4px', cursor:'pointer'}}
                                                                      onClick={async (e)=>{
                                                                          e.preventDefault()
                                                                          let response = await this.asyncGet(Global.API.INSPECTION, {params:{id:item.id}}, null, true, true)
                                                                          if(response.code===200){
                                                                              let inspection = response.data
                                                                              let inspectionEvent = inspection.currentInspectionEvent
                                                                              if(inspectionEvent){
                                                                                  inspectionEvent.inspection = inspection
                                                                              }
                                                                              this.setState({
                                                                                  inspectionEventModal:true,
                                                                                  inspectionEvent:inspectionEvent
                                                                              })
                                                                          }
                                                                      }}>
                                                                    <CardBody>
                                                                        <Row>
                                                                            <Col>
                                                                                <strong>{item.reference}</strong>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                {label.SerialCode} : {item.serialCode?item.serialCode:"NA"}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <Typography type={"text-muted"}>{label.SystemType} : {item.model.systemType}</Typography>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            )
                                                        })}
                                                    </Carousel>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            }
                            <br/>
                            {(mainModels && mainModels.length>0)&&(
                                <>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader>{label.MainModel}</CardHeader>
                                                <CardBody>
                                                    <Carousel
                                                        infinite={true}
                                                        partialVisible={true}
                                                        style={{maxWidth:'10%'}}
                                                        responsive={responsive}
                                                        itemClass="carousel-item-padding-40-px">
                                                        {
                                                            mainModels.map((item, index)=>(
                                                                <Card style={{margin:'12px'}}>
                                                                    <CardBody
                                                                        className="p-1"
                                                                        style={{textAlign:'center', cursor:"pointer"}}
                                                                        onClick={()=>{
                                                                            this.setState({
                                                                                selectedModel:item.model,
                                                                                scanModal:true
                                                                            })
                                                                        }}>
                                                                        <Row>
                                                                            <Col>
                                                                                <MyLetteredAvatar
                                                                                    name={item.model.modelName?item.model.modelName:item.model.systemType}
                                                                                    imgSrc={item.model.modelCategory.icon?item.model.modelCategory.iconUrl:undefined}
                                                                                    options={{
                                                                                        size: 150,
                                                                                        twoLetter: true,
                                                                                        shape: 'square',
                                                                                        bgColor: '',
                                                                                        href: '',
                                                                                        target: 'parent',
                                                                                        tooltip: true,
                                                                                        tooltipTitle: item.model.modelName?item.model.modelName:item.model.systemType,
                                                                                        imgClass: 'image-responsive user-image',
                                                                                        imgWidth: 150,
                                                                                        imgHeight: 150
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <HorizontalLine/>
                                                                        <Row>
                                                                            <Col style={{marginBottom:'0rem'}}>
                                                                                {item.label+" - "+item.model.modelCategory.name}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <strong>{item.model.modelName?item.model.modelName:item.model.systemType}</strong>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            ))
                                                        }
                                                    </Carousel>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <br/>
                                </>
                            )}
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader>{label.ModelList}</CardHeader>
                                        <CardBody>
                                            <Row>
                                                {
                                                    models.map((item, index)=>(
                                                        <Col md={3} className="mb-4">
                                                            <Card>
                                                                <CardBody
                                                                    className="p-1"
                                                                    style={{cursor:"pointer"}}
                                                                    onClick={()=>{
                                                                        this.setState({
                                                                            selectedModel:item,
                                                                            scanModal:true
                                                                        })
                                                                    }}>
                                                                    <Row>
                                                                        <Col md={5}>
                                                                            <MyLetteredAvatar
                                                                                name={item.modelName?item.modelName:item.systemType}
                                                                                imgSrc={item.iconPhoto?item.iconPhotoUrl:null}
                                                                                options={{
                                                                                    size: 80,
                                                                                    twoLetter: true,
                                                                                    shape: 'square',
                                                                                    bgColor: '',
                                                                                    href: '',
                                                                                    target: 'parent',
                                                                                    tooltip: true,
                                                                                    tooltipTitle: item.modelName?item.modelName:item.systemType,
                                                                                    imgClass: 'image-responsive user-image',
                                                                                    imgWidth: 80,
                                                                                    imgHeight: 80
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col md={7}>
                                                                            <strong>{!isEmpty(item.modelName)?item.modelName:item.systemType}</strong>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                    {/* <Col md={3} style={{height:'100%'}}> */}
                    {/* <Card style={{height:'100%'}}> */}

                    <Col md={3} style={{display: 'flex', flex:1}}>
                        <Card style={{width:'100vh'}}>
                            <CardHeader>
                                {label.YourInspectionHistory}
                            </CardHeader>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <SearchInput
                                            value={inspectionEventSearch}
                                            onChange={(e) => {
                                                inspectionEventSearch = e.target.value
                                                inspectionEventPage = 0
                                                inspectionEvents = []
                                                this.setState({
                                                    inspectionEventSearch:inspectionEventSearch,
                                                    inspectionEventPage : 0,
                                                    inspectionEvents : []
                                                },async () => {
                                                    await this.fetchInspectionEvents()
                                                })
                                            }}
                                            onKeyDown={(e)=>{
                                                if (e.key === 'Enter') {
                                                    e.preventDefault()
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="vertical-scrollable">
                                <InfiniteScroll
                                    dataLength={inspectionEvents.length} //This is important field to render the next data
                                    next={async () => {
                                        await this.fetchInspectionEvents()
                                    }}
                                    hasMore={true}
                                    loader={()=>{
                                        if(inspectionEventPage>=inspectionEventTotalPage){
                                            return(<></>)
                                        }else{
                                            return (<Row><Col style={{textAlign:'center', color:'blue'}}><span>Load More...</span></Col></Row>)
                                        }
                                    }}
                                    endMessage={()=>{
                                        if(inspectionEventPage>=inspectionEventTotalPage){
                                            return(<></>)
                                        }else{
                                            return (<Row><Col style={{textAlign:'center', color:'blue'}}><span>Load More...</span></Col></Row>)
                                        }
                                    }}
                                    refreshFunction={async () => {
                                        await this.fetchInspectionEvents()
                                    }}
                                    pullDownToRefresh={false}
                                >
                                    {
                                        inspectionEvents.map((inspectionEvent, index)=>(
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Card className={"clickable"} onClick={(e)=>{
                                                            e.preventDefault()
                                                            this.setState({
                                                                inspectionEventModal:true,
                                                                inspectionEvent:inspectionEvent
                                                            })
                                                        }}>
                                                            <CardHeader
                                                                className="p-2"
                                                                style={{textTransform:'capitalize', fontSize:'0.9rem'}}>
                                                                #{index+1}
                                                                {/*TimeAgo not working on safari */}
                                                                {/* <div className={"float-right"}>
                                                      <TimeAgo formatter={formatter}
                                                      date={inspectionEvent.created}
                                                      />
                                                  </div> */}
                                                                <div className={"float-right"}>
                                                                    {
                                                                        handleTimeAgoWithMillisecond(inspectionEvent.created)
                                                                    }
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="p-2" style={{fontSize:'15px'}}>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <span className={"text-muted"}>{label.Reference}</span>
                                                                    </Col>
                                                                    <Col md={1}>:</Col>
                                                                    <Col>
                                                                        <span className={"text-muted"}>{inspectionEvent.inspection.reference}</span>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <span className={"text-muted"}>SN</span>
                                                                    </Col>
                                                                    <Col md={1}>:</Col>
                                                                    <Col>
                                                                        <span className={"text-muted"}>{inspectionEvent.serialCode}</span>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <span className={"text-muted"}>{label.Model}</span>
                                                                    </Col>
                                                                    <Col md={1}>:</Col>
                                                                    <Col>
                                                                        <span className={"text-muted"}>{inspectionEvent.inspection.model.systemType}</span>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <span className={"text-muted"}>{label.Status}</span>
                                                                    </Col>
                                                                    <Col md={1}>:</Col>
                                                                    <Col>
                                                                        <span className={"text-muted"}><strong>{inspectionEvent.inspection.inspectionStatus.label}</strong></span>
                                                                        <StatusIndicator inspectionStatus={inspectionEvent.inspection.inspectionStatus}/>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <br/>
                                            </>
                                        ))
                                    }
                                </InfiniteScroll>
                                {/*<ReactLoadMore*/}
                                {/*    onBottom={()=>{*/}
                                {/*        this.setState({*/}
                                {/*            inspectionEventPage : inspectionEventPage+1*/}
                                {/*        }, async () => {*/}
                                {/*            await this.fetchInspectionEvents()*/}
                                {/*        })*/}
                                {/*    }}*/}
                                {/*    fetching={fetchInspectionEvents}*/}
                                {/*    hasMore={inspectionEventTotalPage>inspectionEventPage}*/}
                                {/*    NoResult={()=>{*/}
                                {/*        if(inspectionEvents.length>0){*/}
                                {/*            return (<></>)*/}
                                {/*        }else{*/}
                                {/*            return(*/}
                                {/*                <Row>*/}
                                {/*                    <Col style={{textAlign:'center', fontSize:'1.8rem', color:'black'}}>*/}
                                {/*          <span>*/}
                                {/*              <Image style={{maxWidth:'96px'}} src={emptyBoxPng}/>*/}
                                {/*              <br/>*/}
                                {/*              {label.ThereIsNoHistory}...*/}
                                {/*          </span>*/}
                                {/*                    </Col>*/}
                                {/*                </Row>*/}
                                {/*            )*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    Footer={()=>{*/}
                                {/*        if(inspectionEventPage>=inspectionEventTotalPage){*/}
                                {/*            return(<></>)*/}
                                {/*        }else{*/}
                                {/*            return (<Row><Col style={{textAlign:'center', color:'blue'}}><span>Load More...</span></Col></Row>)*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {*/}
                                {/*        inspectionEvents.map((inspectionEvent, index)=>(*/}
                                {/*            <>*/}
                                {/*                <Row>*/}
                                {/*                    <Col>*/}
                                {/*                        <Card className={"clickable"} onClick={(e)=>{*/}
                                {/*                            e.preventDefault()*/}
                                {/*                            this.setState({*/}
                                {/*                                inspectionEventModal:true,*/}
                                {/*                                inspectionEvent:inspectionEvent*/}
                                {/*                            })*/}
                                {/*                        }}>*/}
                                {/*                            <CardHeader*/}
                                {/*                                className="p-2"*/}
                                {/*                                style={{textTransform:'capitalize', fontSize:'0.9rem'}}>*/}
                                {/*                                #{index+1}*/}
                                {/*                                /!*TimeAgo not working on safari *!/*/}
                                {/*                                /!* <div className={"float-right"}>*/}
                                {/*                      <TimeAgo formatter={formatter}*/}
                                {/*                      date={inspectionEvent.created}*/}
                                {/*                      />*/}
                                {/*                  </div> *!/*/}
                                {/*                                <div className={"float-right"}>*/}
                                {/*                                    {handleTimeAgo(inspectionEvent.created)}*/}

                                {/*                                </div>*/}
                                {/*                            </CardHeader>*/}
                                {/*                            <CardBody className="p-2" style={{fontSize:'15px'}}>*/}
                                {/*                                <Row>*/}
                                {/*                                    <Col md={4}>*/}
                                {/*                                        <span className={"text-muted"}>{label.Reference}</span>*/}
                                {/*                                    </Col>*/}
                                {/*                                    <Col md={1}>:</Col>*/}
                                {/*                                    <Col>*/}
                                {/*                                        <span className={"text-muted"}>{inspectionEvent.inspection.reference}</span>*/}
                                {/*                                    </Col>*/}
                                {/*                                </Row>*/}
                                {/*                                <Row>*/}
                                {/*                                    <Col md={4}>*/}
                                {/*                                        <span className={"text-muted"}>SN</span>*/}
                                {/*                                    </Col>*/}
                                {/*                                    <Col md={1}>:</Col>*/}
                                {/*                                    <Col>*/}
                                {/*                                        <span className={"text-muted"}>{inspectionEvent.serialCode}</span>*/}
                                {/*                                    </Col>*/}
                                {/*                                </Row>*/}
                                {/*                                <Row>*/}
                                {/*                                    <Col md={4}>*/}
                                {/*                                        <span className={"text-muted"}>{label.Model}</span>*/}
                                {/*                                    </Col>*/}
                                {/*                                    <Col md={1}>:</Col>*/}
                                {/*                                    <Col>*/}
                                {/*                                        <span className={"text-muted"}>{inspectionEvent.inspection.model.systemType}</span>*/}
                                {/*                                    </Col>*/}
                                {/*                                </Row>*/}
                                {/*                                <Row>*/}
                                {/*                                    <Col md={4}>*/}
                                {/*                                        <span className={"text-muted"}>{label.Status}</span>*/}
                                {/*                                    </Col>*/}
                                {/*                                    <Col md={1}>:</Col>*/}
                                {/*                                    <Col>*/}
                                {/*                                        <span className={"text-muted"}><strong>{inspectionEvent.inspection.inspectionStatus.label}</strong></span>*/}
                                {/*                                        <StatusIndicator inspectionStatus={inspectionEvent.inspection.inspectionStatus}/>*/}
                                {/*                                    </Col>*/}
                                {/*                                </Row>*/}
                                {/*                            </CardBody>*/}
                                {/*                        </Card>*/}
                                {/*                    </Col>*/}
                                {/*                </Row>*/}
                                {/*                <br/>*/}
                                {/*            </>*/}
                                {/*        ))*/}
                                {/*    }*/}
                                {/*</ReactLoadMore>*/}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <SockJsClient
                    url={Global.BASE_URL}
                    topics={[Global.API.LISTEN_UPDATE_MODEL]}
                    getRetryInterval={()=>{return 5}}
                    heartbeat={60000}
                    onMessage={(message) => {
                        this.fetchMainModels()
                        this.fetchModels()
                    }}
                    getRetryInterval={()=>{return 5}}
                    heartbeat={60000}
                    ref={ (client) => { this.clientRef = client }} />
            </>
        );
    }
}
export default Object.assign(MainDashboardPage, {ENDPOINT : "/"})
