import React from 'react';
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import label from '../../message/Label'
import Global from "../../utils/Global";
import {isEmpty, isSuccess} from "../../utils/Utilities";
import WarningLabel from "../Widget/WarningLabel";

const { htmlToText } = require('html-to-text');


/*= (message, okCallback, cancelCallback) =>*/
export default class BatchPrintInputModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.startSerialNumber = ""
        state.endSerialNumber = ""
        state.showing = this.props.showing
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                startSerialNumber : "",
                endSerialNumber : "",
                showing:props.showing,
            })
        }
    }

    async onBatchApply(){
        let {showing, startSerialNumber, endSerialNumber, startSerialNumberWarning, endSerialNumberWarning} = this.state
        if(isEmpty(startSerialNumber)){
            this.setState({
                startSerialNumberWarning:label.EnterStartSerialNumber
            })
            return ;
        }
        if(isEmpty(endSerialNumber)){
            this.setState({
                endSerialNumberWarning:label.EnterEndSerialNumber
            })
            return ;
        }
        let form = new FormData()
        form.append("startSerialNumberCode", startSerialNumber)
        form.append("endSerialNumberCode", endSerialNumber)

        let response = await this.asyncPost(Global.API.VALIDATE_SERIAL_NUMBER_RANGE, form, null, true, false)
        // console.log("form response",response)
        //
        if(isSuccess(response)){
            this.props.onOkBatchPrint(startSerialNumber, endSerialNumber, response.data)
        }else{
            let errorMap = response.data
            if(errorMap.start){
                this.setState({startSerialNumberWarning:errorMap.start})
                return
            }
            if(errorMap.end){
                this.setState({endSerialNumberWarning:errorMap.end})
                return
            }
        }
    }


    render(){
        let {showing, startSerialNumber, endSerialNumber, startSerialNumberWarning, endSerialNumberWarning} = this.state
        return (
            /*xl-lg-sm*/
            <Modal
                autoFocus={false}
                centered={true}
                isOpen={showing}
                backdrop={true}
                zIndex="1">
                {super.render()}
                <ModalHeader>
                    {label.EnterSerialNumberRangeToPrint}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="startSerialNumber">{label.StartSerialNumber}</Label>
                                <Input
                                    type="text"
                                    name="startSerialNumber"
                                    value={startSerialNumber}
                                    onChange={(e)=>{
                                        startSerialNumber= e.target.value
                                        this.setState({startSerialNumber, startSerialNumberWarning:""})
                                    }}
                                    placeholder={label.EnterStartSerialNumber}
                                />
                                <WarningLabel show={startSerialNumberWarning} message={startSerialNumberWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="endSerialNumber">{label.EndSerialNumber}</Label>
                                <Input
                                    type="text"
                                    name="client"
                                    value={endSerialNumber}
                                    onChange={(e)=>{
                                        endSerialNumber = e.target.value
                                        this.setState({endSerialNumber, endSerialNumberWarning:""})
                                    }}
                                    placeholder={label.EnterEndSerialNumber}
                                />
                                <WarningLabel show={endSerialNumberWarning} message={endSerialNumberWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="warning"
                        onClick={async (e)=>{
                            e.preventDefault()
                            this.props.onClose()
                        }}>
                        {label.Close}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={async (e)=>{
                            e.preventDefault()
                            await this.onBatchApply()
                        }}>
                        {label.BatchPrint}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

BatchPrintInputModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onOkBatchPrint:PropTypes.func.isRequired,
}
