import React from 'react';
import BaseComponent from '../components/BaseComponent';
import {validatePrivilege} from '../utils/Utilities';


export default class BasePage extends BaseComponent{

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        super.componentDidMount();
        validatePrivilege(this.props)
    }

    // render() {
    //     return (
    //         <div>
    //             {super.render()}
    //         </div>
    //     );
    // }

}
