import React from 'react';
import {STATE_LOGIN} from './components/AuthForm';
import GAListener from './components/GAListener';
import {EmptyLayout, LayoutRoute, MainLayout} from './components/Layout';
import AuthPage from './pages/AuthPage';
import {AuthProvider} from './utils/AuthContext';
import kitchen from '../src/utils/AxiosInstance';
import componentQueries from 'react-component-queries';
import {BrowserRouter, Redirect, Switch} from 'react-router-dom';
import './styles/reduction.scss';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import ProfilePage from './pages/ProfilePage';
import Global from './utils/Global';
import {storeData} from './utils/StorageUtil';
import UserActivityPage from './pages/UserActivityPage';
import MainDashboardPage from './pages/MainDashboardPage';
import './App.css';
import InspectionPage from './pages/InspectionPage';
import UserPage from './pages/UserPage';
import UserDetailPage from './pages/UserDetailPage';
import InspectionDetailPage from './pages/InspectionDetailPage';
import InspectionEventPage from "./pages/InspectionEventPage";
import InspectionFormCheckPage from "./pages/InspectionFormCheckPage";
import InspectionEventDetailPage from "./pages/InspectionEventDetailPage";
import NetworkMeterPage from "./pages/NetworkMeterPage";
import SerialNumberPage from "./pages/SerialNumberPage";
import TokenPage from "./pages/TokenPage";
import PublicRoute from "./components/Layout/PublicRoute";


const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {


  componentDidMount() {
    fetchAppSetting()
  }


  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <AuthProvider>
          <GAListener>
            <Switch>

              <ProtectedRoute
                exact
                rootMenu={InspectionPage.ENDPOINT}
                path={InspectionPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionPage}
              />
              <ProtectedRoute
                exact
                rootMenu={InspectionPage.ENDPOINT}
                path={InspectionDetailPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={MainDashboardPage.ENDPOINT}
                path={InspectionFormCheckPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionFormCheckPage}
              />

              <ProtectedRoute
                exact
                rootMenu={InspectionEventPage.ENDPOINT}
                path={InspectionEventPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionEventPage}
              />

              <ProtectedRoute
                exact
                rootMenu={InspectionEventPage.ENDPOINT}
                path={InspectionEventDetailPage.ENDPOINT}
                layout={MainLayout}
                component={InspectionEventDetailPage}
              />

              <ProtectedRoute
                exact
                rootMenu={UserPage.ENDPOINT}
                path={UserPage.ENDPOINT}
                layout={MainLayout}
                component={UserPage}
              />

              <ProtectedRoute
                exact
                rootMenu={UserPage.ENDPOINT}
                path={UserDetailPage.ENDPOINT}
                layout={MainLayout}
                component={UserDetailPage}
              />


              <ProtectedRoute
                exact
                rootMenu={UserActivityPage.ENDPOINT}
                path={UserActivityPage.ENDPOINT}
                layout={MainLayout}
                component={UserActivityPage}
              />

              <LayoutRoute
                exact
                path={AuthPage.ENDPOINT}
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
              <PublicRoute
                  exact
                  path={NetworkMeterPage.ENDPOINT}
                  layout={EmptyLayout}
                  component={props => (
                      <NetworkMeterPage {...props}/>
                  )}
              />

              <ProtectedRoute
                exact
                rootMenu={MainDashboardPage.ENDPOINT}
                path={MainDashboardPage.ENDPOINT}
                layout={MainLayout}
                component={MainDashboardPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ProfilePage.ENDPOINT}
                path={ProfilePage.ENDPOINT}
                layout={MainLayout}
                component={ProfilePage}
              />

              <ProtectedRoute
                  exact
                  rootMenu={SerialNumberPage.ENDPOINT}
                  path={SerialNumberPage.ENDPOINT}
                  layout={MainLayout}
                  component={SerialNumberPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={TokenPage.ENDPOINT}
                  path={TokenPage.ENDPOINT}
                  layout={MainLayout}
                  component={TokenPage}
              />
              <Redirect to="/" />
            </Switch>
          </GAListener>
        </AuthProvider>
      </BrowserRouter>
    );
  }
}
function fetchAppSetting() {
  get(Global.API.CONFIGURATION_CURRENT_ACTIVE, null, null, response => {
    if (response.data.code === 200) {
      storeData(Global.CONFIGURATION, response.data.data)
    }
  })
}

function get(url, params, config, callback) {
  kitchen.get(url, params, config).then(response => {
    callback(response)
  }).catch(e => {

  });
}




const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
