import * as React from 'react';
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import label from '../../message/Label'
import sucessIconImage from '../../assets/img/widget/success_icon.png';
import * as PropTypes from "prop-types";
import MyCardImage from "../Card/MyCardImage";

export default class SuccessDialog extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            modal_backdrop: false,
            modal_nested_parent: false,
            modal_nested: false,
            backdrop: true,
            title : this.props.title,
            message : this.props.message,
        }

    }

    componentWillReceiveProps(props , nextContext) {
        if(this.props!=props && props.showing){
            this.setState({
                title : props.title,
                message : props.message,
            })
        }
    }

    render(){
        return (
            <Modal
                centered={true}
                isOpen={this.props.showing}
                backdrop={true}>
                <ModalHeader>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody style={{textAlign:'center'}}>
                                    <MyCardImage style={{width:'60%'}} src={sucessIconImage}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <p style={{textAlign:'center', fontSize:'22px'}}>
                        {this.props.message}
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        block
                        color="primary"
                        onClick={e=>{
                            if(this.props.okCallback!=null){
                                this.props.okCallback()
                            }
                        }}>
                        {label.Ok}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

SuccessDialog.propTypes = {
    showing:PropTypes.bool.isRequired,
    title:PropTypes.string.isRequired,
    message:PropTypes.string.isRequired,
    okCallback:PropTypes.func.isRequired,
    cancelCallback:PropTypes.func.isRequired
}
