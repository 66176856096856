import React from 'react';
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import label from '../../message/Label'
import {isEmpty} from "../../utils/Utilities";
import WarningLabel from "../Widget/WarningLabel";

const { htmlToText } = require('html-to-text');


/*= (message, okCallback, cancelCallback) =>*/
export default class TokenInputModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.token = ""
        state.tokenError = ""
        state.showing = this.props.showing
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                token : "",
                tokenError:"",
                showing:props.showing,
            })
        }
    }

    onEnterToken(){
        let {token} = this.state
        this.props.onEnterToken(token)

    }

    render(){
        let {showing, token, tokenError} = this.state
        return (
            /*xl-lg-sm*/
            <Modal
                autoFocus={false}
                centered={true}
                isOpen={showing}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.EnterTokenToContinue}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="token">{label.EnterToken}</Label>
                                <Input
                                    style={{textAlign:'center', fontSize:'3rem', letterSpacing:'12px'}}
                                    type="text"
                                    name="token"
                                    value={token}
                                    maxLength={6}
                                    onChange={(e)=>{
                                        token = e.target.value
                                        token = token.replace(/\D/g,'')
                                        this.setState({token, tokenError:""})
                                    }}
                                    onKeyDown={(e)=>{
                                        if(e.key==='Enter'){
                                            e.preventDefault()
                                            if(token.length==6){
                                                this.onEnterToken()
                                            }
                                        }
                                    }}
                                    placeholder={"000000"}
                                />
                                <WarningLabel show={!isEmpty(tokenError)} message={tokenError}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="warning"
                        onClick={async (e)=>{
                            e.preventDefault()
                            this.props.onClose()
                        }}>
                        {label.Cancel}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={(e)=>{
                            e.preventDefault()
                            this.onEnterToken()
                        }}>
                        {label.Continue}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

TokenInputModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onEnterToken:PropTypes.func.isRequired,
}
