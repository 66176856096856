import React from 'react';
import './ProgressDialog.css';

export default class ProgressDialog extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            showing:false
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                showing:props.showing,
            })
        }
    }


    render() {
        return (
            this.state.showing?
                <div>
                    <div className="loading-container">
                        <div className="loading-wrap">
                            <div className="loading loader">

                            </div>
                        </div>
                    </div>

                </div>
                :
                null
        );
    }
}
