import React from 'react';
import {
    ButtonGroup,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from 'reactstrap';
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import {FormType} from "../../model/formbuilder/FormType";
import Typography from "../Typography";
import imageGallery from '../../assets/img/image_gallery.png';
import HorizontalLine from "./HorizontalLine";
import {MdCamera, MdPermMedia} from "react-icons/all"
import CameraModal from "../modal/CameraModal";
import WarningLabel from "./WarningLabel";
import MyCardImage from "../Card/MyCardImage";
import {fileToBase64, imageSelector, isSuccess} from "../../utils/Utilities";
import Global, {FORM_IMAGE_MAX_FILE_SIZE, MIME_PNG_JPEG} from "../../utils/Global";
import label from "../../message/Label"
import clone from 'fclone'

export default class FormCheckWidget extends BaseComponent{
 
    constructor(props) {
        super(props);
        let state = this.state
        state.inspection = this.props.inspection
        state.inspectionEvent = this.props.inspectionEvent
        state.formLaneApplication = this.props.formLaneApplication?this.props.formLaneApplication:{}
        state.formApplication = this.props.formApplication?this.props.formApplication:{}
        state.stepFormLaneId = this.props.stepFormLaneId ;
        state.cameraFormShow = false ;
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let formApplication =  this.state.formApplication?this.state.formApplication:{}
        if(props!=this.props){
            let newFormApplication = props.formApplication?props.formApplication:{}
            if (JSON.stringify(newFormApplication)!==JSON.stringify(formApplication)){
                let state = this.state
                state.inspection = props.inspection
                state.inspectionEvent = props.inspectionEvent
                state.formLaneApplication = props.formLaneApplication?props.formLaneApplication:{}
                state.formApplication = props.formApplication?props.formApplication:{}
                state.stepFormLaneId = props.stepFormLaneId ;
                state.cameraFormShow = false ;
                this.setState(state)
            }
        }
    }

    renderSubInputs=(formApplication)=>{
        let subFormApplications = formApplication.subFormApplications?formApplication.subFormApplications:[]
        if(!subFormApplications || subFormApplications.length<=0){
            return ;
        }
        return(
            <>
                <HorizontalLine/>
                <Row>
                {
                    subFormApplications.map((subFormApplication, index) =>
                        this.renderSubInput(subFormApplications, formApplication, subFormApplication, index)
                    )
                }
                </Row>
            </>
        )
    }

    renderSubInput=(subFormApplications, formApplication, subFormApplication, index)=>{
        let {cameraFormShow, enableClick} = this.state
        if(!enableClick){
            enableClick = {}
        }
        let form = subFormApplication.form?subFormApplication.form:{}
        if(form.type==FormType.TEXT){
            return (
                <Col md={12}>
                    <FormGroup>
                        <Label for="name">{form.title}</Label>
                        <Input
                            className={form.error?"form-error":undefined}
                            color={"danger"}
                            type="textarea"
                            name="title"
                            rows={form.line>0?form.line:1}
                            maxLength={form.maxLength>0?form.maxLength:undefined}
                            value={subFormApplication.value}
                            onChange={(e)=> {
                                let value = e.target.value
                                subFormApplication.value = value
                                form.error = false
                                this.setState({
                                    subFormApplication:subFormApplication
                                })
                            }}/>
                        <WarningLabel show={form.error==true} message={form.message}/>
                    </FormGroup>
                </Col>
            );
        }
        if(form.type==FormType.POSITIVE_NEGATIVE){
            return (
                <Col md={12}>
                    <FormGroup>
                        <Label for="name">{form.title}</Label>
                        <Row>
                            <Col>
                                <InputGroup
                                    className={"clickable "+(form.error?"form-error":undefined)}
                                            onClick={()=>{
                                                if(subFormApplication.value===form.positiveValue){
                                                    subFormApplication.value = undefined
                                                }else{
                                                    subFormApplication.value = form.positiveValue
                                                }
                                                form.error = false
                                                this.setState({
                                                    formApplication:formApplication
                                                })
                                            }}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <Input checked={form.positiveValue===subFormApplication.value}
                                                addon type="checkbox"
                                                aria-label={form.positiveValue}/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input disabled placeholder={form.positiveValue} />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup
                                    className={"clickable "+(form.error?"form-error":undefined)}
                                    onClick={()=>{
                                                if(subFormApplication.value===form.negativeValue){
                                                    subFormApplication.value = undefined
                                                }else{
                                                    subFormApplication.value = form.negativeValue
                                                }
                                                form.error = false
                                                this.setState({
                                                    formApplication:formApplication
                                                })
                                            }}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <Input
                                                checked={form.negativeValue===subFormApplication.value}
                                                addon type="checkbox"
                                                aria-label={form.negativeValue}/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input disabled placeholder={form.negativeValue} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <WarningLabel show={form.error==true} message={form.message}/>
                    </FormGroup>
                </Col>
            );
        }
        if(form.type==FormType.IMAGE){
            return (
                <>
                    {super.render()}
                    <CameraModal
                        formApplication={subFormApplication}
                        showing={this.state[cameraFormShow+index]}
                        onClose={() => {
                            this.setState({
                                [cameraFormShow+index]:false,
                            })
                        }}
                        onTakePicture={(resultBase64Image) => {
                            form.error = false
                            subFormApplication.imageUrl = resultBase64Image
                            this.setState({
                                [cameraFormShow+index]:false,
                            }, () => {
                                this.saveSubFormApplicationImage(formApplication, subFormApplication)
                            })
                        }}
                    />
                    <Col md={6}>
                        <FormGroup>
                            <Label for="name">{form.title}</Label>
                            <Card className={form.error?"form-error":undefined}>
                                <CardBody>
                                    <MyCardImage
                                        src={subFormApplication.imageUrl?subFormApplication.imageUrl:imageGallery}
                                        onError={(elm)=>this.defaultImage(elm, imageGallery)}
                                        onLoad={()=>{
                                            enableClick[subFormApplication.tag] = true
                                            this.setState({
                                                enableClick
                                            })
                                        }}
                                        onClick={()=>{
                                            if(enableClick[subFormApplication.tag]){
                                                if(subFormApplication.imageUrl){
                                                    this.openLightBoxSingleImage(subFormApplication.imageUrl)
                                                }
                                            }
                                        }}/>
                                    <WarningLabel show={form.error==true} message={form.message}/>
                                </CardBody>
                                <CardFooter>
                                    <ButtonGroup className="float-right">
                                        <Button color="primary" onClick={e=>{
                                            imageSelector( file=>{
                                                if(file){
                                                    this.downloadBlobFile(file, async (fileBlob)=>{
                                                        let resultBase64Image = await fileToBase64(fileBlob)
                                                        subFormApplication.imageUrl = resultBase64Image
                                                        this.saveSubFormApplicationImage(formApplication, subFormApplication)
                                                        // this.setState({
                                                        //     subFormApplication : subFormApplication
                                                        // })
                                                    })
                                                }
                                            }, MIME_PNG_JPEG, FORM_IMAGE_MAX_FILE_SIZE, false, this).click()
                                        }}><MdPermMedia/></Button>
                                        &nbsp;&nbsp;
                                        <Button onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({
                                                [cameraFormShow+index] : true
                                            })
                                        }}><MdCamera/></Button>
                                    </ButtonGroup>
                                </CardFooter>
                            </Card>
                        </FormGroup>
                    </Col>
                </>
            );
        }
        return (
            <></>
        )
    }

    saveFormApplicationImage(formApplication){
        let {inspection, formLaneApplication, inspectionEvent, enableClick} = this.state
        if(!enableClick){
            enableClick = {}
        }
        this.post(Global.API.INSPECTION_SAVE_FORM_APPLICATION_IMAGE+"?inspectionId="+inspection.id+"&formLaneApplicationId="+formLaneApplication.id, formApplication,
            null, (response)=>{
                if(isSuccess(response)){
                        let formApplication = response.data
                        let formLaneApplication = formApplication.formLaneApplication
                        inspectionEvent.formLaneApplications.forEach((value, index) => {
                            console.log(" LANE iD = "+value.laneId+" - "+formLaneApplication.laneId)
                            // if(value.laneId === formLaneApplication.laneId ){
                            //     inspectionEvent.formLaneApplications[index].formApplications.forEach((value1, index1) => {
                            //         console.log(" FORM iD = "+value1.form.id+" - "+formApplication.form.id)
                            //         if(value1.form.id===formApplication.form.id){
                            //             inspectionEvent.formLaneApplications[index].formApplications[index1] = formApplication
                            //             enableClick[formApplication.tag] = false
                            //             this.setState({
                            //                 formApplication:formApplication,
                            //                 inspectionEvent:inspectionEvent,
                            //                 enableClick
                            //             })
                            //         }
                            //     })
                            // }
                        })
                    }

            },true, true);
    }

    saveSubFormApplicationImage(formApplication, subFormApplication){
        let {inspection, formLaneApplication, inspectionEvent, enableClick} = this.state
        if(!enableClick){
            enableClick = {}
        }
        this.post(Global.API.INSPECTION_SAVE_SUB_FORM_APPLICATION_IMAGE+"?inspectionId="+inspection.id
                            +"&formApplicationId="+formApplication.id, subFormApplication,
            null, (response)=>{
                if(isSuccess(response)){
                    let subFormApplication = response.data
                    let formApplication = subFormApplication.formApplication
                    let formLaneApplication = formApplication.formLaneApplication
                    inspectionEvent.formLaneApplications.forEach((value, index) => {
                        console.log(" Lane iD = "+value.laneId+" - "+formLaneApplication.laneId)
                        // if(value.laneId === formLaneApplication.laneId){
                        //     inspectionEvent.formLaneApplications[index].formApplications.forEach((value1, index1) => {
                        //         if(value1.id===formApplication.id){
                        //             inspectionEvent.formLaneApplications[index].formApplications[index1]
                        //                 .subFormApplications.forEach((value2, index2) => {
                        //                     console.log(" FORM iD = "+value2.form.id+" - "+subFormApplication.form.id)
                        //                     if(value2.form.id===subFormApplication.form.id){
                        //                         inspectionEvent.formLaneApplications[index].formApplications[index1]
                        //                             .subFormApplications[index2] = subFormApplication
                        //                         enableClick[subFormApplication.tag] = false ;
                        //                         this.setState({subFormApplication, inspectionEvent:inspectionEvent, enableClick})
                        //                     }
                        //             })
                        //         }
                        //     })
                        // }
                    })
                    // formApplication = response.data
                }

            },true, true);
    }



    render() {
        let {inspection, inspectionEvent, formLaneApplication, stepFormLaneId, formApplication, cameraFormShow, enableClick} = this.state
        let formApplications = formLaneApplication.formApplications?formLaneApplication.formApplications:[]
        let form = formApplication.form?formApplication.form:{}
        if(!enableClick){
            enableClick = {}
        }
        if(stepFormLaneId!==formLaneApplication.laneId){
            return null;
        }

        if(form.type===FormType.TEXT){
            return (
                <>
                    <Col md={4} key={formApplication.id}>
                        <Card className={form.error?"form-error":undefined}>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="name"><strong>{form.tag}</strong>&nbsp;-&nbsp;{form.title}</Label>
                                            <Input
                                                color={"danger"}
                                                type="textarea"
                                                name="title"
                                                rows={form.line>0?form.line:1}
                                                maxLength={form.maxLength>0?form.maxLength:undefined}
                                                value={formApplication.value}
                                                onChange={(e)=> {
                                                    let value = e.target.value
                                                    let unallowedCharacters = form.unallowedCharacters?form.unallowedCharacters:""
                                                    for (let i = 0; i < unallowedCharacters.length; i++) {
                                                        value = value.replace(unallowedCharacters.charAt(i), "")
                                                    }
                                                    formApplication.value = value
                                                    form.error = false
                                                    this.setState({
                                                        formApplication:formApplication
                                                    })
                                                }}/>
                                            <WarningLabel show={form.error==true} message={form.message}/>
                                            <br/>
                                            <Typography className="text-muted"><em>{form.placeHolder}</em></Typography>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {this.renderSubInputs(formApplication)}
                            </CardBody>
                        </Card>
                    </Col>
                </>
            );
        }
        if(form.type==FormType.POSITIVE_NEGATIVE){
            return (
                <>
                    <Col  md={4} key={formApplication.id}>
                        <Card className={form.error?"form-error":undefined}>
                            <CardBody>
                                <FormGroup>
                                    <Label for="name"><strong>{form.tag}</strong>&nbsp;-&nbsp;{form.title}</Label>
                                    <Row>
                                        <Col>
                                            <InputGroup className={"clickable"}
                                                        onClick={()=>{
                                                            if(formApplication.value===form.positiveValue){
                                                                formApplication.value = undefined
                                                            }else{
                                                                formApplication.value = form.positiveValue
                                                            }
                                                            form.error = false
                                                            this.setState({
                                                                formApplication:formApplication
                                                            })
                                                        }}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <Input
                                                            checked={form.positiveValue===formApplication.value}
                                                            addon type="checkbox"
                                                            aria-label={form.positiveValue}/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input disabled placeholder={form.positiveValue} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className={"clickable"}
                                                        onClick={()=>{
                                                            if(formApplication.value===form.negativeValue){
                                                                formApplication.value = undefined
                                                            }else{
                                                                formApplication.value = form.negativeValue
                                                            }

                                                            form.error = false
                                                            this.setState({
                                                                formApplication:formApplication
                                                            })
                                                        }}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <Input
                                                            checked={form.negativeValue===formApplication.value}
                                                            addon type="checkbox"
                                                            aria-label={form.negativeValue}/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input disabled placeholder={form.negativeValue} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <WarningLabel show={form.error==true} message={form.message}/>
                                    <Typography className="text-muted"><em>{form.placeHolder}</em></Typography>
                                </FormGroup>
                                {this.renderSubInputs(formApplication)}
                            </CardBody>
                        </Card>
                    </Col>
                </>
            );
        }
        if(form.type==FormType.IMAGE){
            return (
                <>
                    <CameraModal
                        formApplication={formApplication}
                        showing={cameraFormShow}
                        onClose={() => {
                            this.setState({
                                cameraFormShow:false,
                            })
                        }}
                        onTakePicture={(resultBase64Image) => {
                            formApplication.imageUrl = resultBase64Image
                            form.error = false
                            this.setState({
                                cameraFormShow:false,
                                formApplication:formApplication,
                            }, () => {
                                this.saveFormApplicationImage(formApplication)
                            })
                        }}
                    />
                    <Col md={4} key={formApplication.id}>
                        {super.render()}
                        <Card className={form.error?"form-error":undefined}>
                            <CardBody>
                                <FormGroup>
                                    <Label for="name"><strong>{form.tag}</strong>&nbsp;-&nbsp;{form.title}</Label>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardImg
                                                        src={formApplication.imageUrl?formApplication.imageUrl:imageGallery}
                                                        onError={(elm)=>this.defaultImage(elm, imageGallery)}
                                                        onLoad={()=>{
                                                            enableClick[formApplication.tag] = true
                                                            this.setState({
                                                                enableClick
                                                            })
                                                        }}
                                                        onClick={()=>{
                                                            if(enableClick[formApplication.tag]){
                                                                if(formApplication.imageUrl){
                                                                    this.openLightBoxSingleImage(formApplication.imageUrl)
                                                                }
                                                            }
                                                        }}/>
                                                </CardBody>
                                                <CardFooter>
                                                    <ButtonGroup className="float-right">
                                                        <Button color="primary" onClick={e=>{
                                                            imageSelector( file=>{
                                                                if(file){
                                                                    this.downloadBlobFile(file, async (fileBlob)=>{
                                                                        let resultBase64Image = await fileToBase64(fileBlob)
                                                                        formApplication.imageUrl = resultBase64Image
                                                                       this.saveFormApplicationImage(formApplication)
                                                                        // this.setState({
                                                                        //     formApplication : formApplication
                                                                        // })
                                                                    })
                                                                }
                                                            }, MIME_PNG_JPEG, FORM_IMAGE_MAX_FILE_SIZE, false, this).click()
                                                        }}><MdPermMedia/></Button>
                                                        &nbsp;&nbsp;
                                                        <Button onClick={() => {
                                                            this.setState({
                                                                cameraFormShow : true
                                                            })
                                                        }}><MdCamera/></Button>
                                                    </ButtonGroup>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <WarningLabel show={form.error==true} message={form.message}/>
                                    <HorizontalLine />
                                    <Typography className="text-muted"><em>{form.placeHolder}</em></Typography>
                                </FormGroup>
                                {this.renderSubInputs(formApplication)}
                            </CardBody>
                        </Card>
                    </Col>
                </>
            );
        }

        return (
            <></>
        )
    }
}

FormCheckWidget.propTypes = {
    inspection:PropTypes.object.isRequired,
    inspectionEvent:PropTypes.object.isRequired,
    stepFormLaneId:PropTypes.object.isRequired,
    formLaneApplication:PropTypes.object.isRequired,
    formApplication:PropTypes.object.isRequired,
}

