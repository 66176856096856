import React from 'react';
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import BaseComponent from '../BaseComponent';
import PropTypes from 'prop-types';
import label from '../../message/Label'
import Global from "../../utils/Global";
import {isEmpty, isSuccess} from "../../utils/Utilities";
import WarningLabel from "../Widget/WarningLabel";

const { htmlToText } = require('html-to-text');


/*= (message, okCallback, cancelCallback) =>*/
export default class CustomerInfoModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.inspectionEvent = this.props.inspectionEvent?this.props.inspectionEvent:{}
        state.showing = this.props.showing
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                inspectionEvent : props.inspectionEvent?props.inspectionEvent:{},
                showing:props.showing,
            })
        }
    }

    async updateCustomerInfo(){
        let {inspectionEvent} = this.state
        if(isEmpty(inspectionEvent.customerOrderNo)){
            this.setState({
                customerOrderNoWarning:label.EnterCustomerOrderNo
            })
            return ;
        }
        if(isEmpty(inspectionEvent.client)){
            this.setState({
                clientWarning:label.EnterClient
            })
            return ;
        }
        if(isEmpty(inspectionEvent.productSKU)){
            this.setState({
                productSKUWarning:label.EnterProductSKU
            })
            return ;
        }
        if(isEmpty(inspectionEvent.productName)){
            this.setState({
                productNameWarning:label.EnterProductName
            })
            return ;
        }
        this.props.onClose()
        let form = new FormData()
        form.append("id", inspectionEvent.id)
        form.append("customerOrderNo", inspectionEvent.customerOrderNo)
        form.append("client", inspectionEvent.client)
        form.append("productSKU", inspectionEvent.productSKU)
        form.append("productName", inspectionEvent.productName)
        // console.log("form",form)
        // let params = {
        //     id : inspectionEvent.id,
        //     customerOrderNo : inspectionEvent.customerOrderNo,
        //     client : inspectionEvent.client,
        //     productSKU : inspectionEvent.productSKU,
        //     productName : inspectionEvent.productName,
        // }
        let response = await this.asyncPost(Global.API.INSPECTION_EVENT_UPDATE_CUSTOMER_INFO, form, null, false, false)
        console.log("form response",response)

        if(isSuccess(response)){
            this.props.onSaved(response.data)
        }
    }


    render(){
        let {showing, inspectionEvent, customerOrderNoWarning, clientWarning,productSKUWarning,productNameWarning} = this.state
        return (
            /*xl-lg-sm*/
            <Modal
                autoFocus={false}
                size="lg"
                centered={true}
                isOpen={showing}
                backdrop={true}
                zIndex="1">
                {super.render()}
                <ModalHeader>
                    {label.CustomerInfo}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="customerOrderNo">{label.CustomerOrderNo}</Label>
                                <Input
                                    type="text"
                                    name="customerOrderNo"
                                    value={inspectionEvent.customerOrderNo?inspectionEvent.customerOrderNo:""}
                                    onChange={(e)=>{
                                        inspectionEvent.customerOrderNo = e.target.value
                                        this.setState({inspectionEvent:inspectionEvent, customerOrderNoWarning:""})
                                    }}
                                    placeholder={label.EnterCustomerOrderNo}
                                />
                                <WarningLabel show={customerOrderNoWarning} message={customerOrderNoWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="client">{label.Client}</Label>
                                <Input
                                    type="text"
                                    name="client"
                                    value={inspectionEvent.client?inspectionEvent.client:""}
                                    onChange={(e)=>{
                                        inspectionEvent.client = e.target.value
                                        this.setState({inspectionEvent:inspectionEvent, clientWarning:""})
                                    }}
                                    placeholder={label.EnterClient}
                                />
                                <WarningLabel show={clientWarning} message={clientWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="client">{label.ProductSKU}</Label>
                                <Input
                                    type="text"
                                    name="productSKU"
                                    value={inspectionEvent.productSKU?inspectionEvent.productSKU:""}
                                    onChange={(e)=>{
                                        inspectionEvent.productSKU = e.target.value
                                        this.setState({inspectionEvent:inspectionEvent, productSKUWarning:""})
                                    }}
                                    placeholder={label.EnterProductSKU}
                                />
                                <WarningLabel show={productSKUWarning} message={productSKUWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="client">{label.ProductName}</Label>
                                <Input
                                    type="text"
                                    name="productName"
                                    value={inspectionEvent.productName?inspectionEvent.productName:""}
                                    onChange={(e)=>{
                                        inspectionEvent.productName = e.target.value
                                        this.setState({inspectionEvent:inspectionEvent, productNameWarning:""})
                                    }}
                                    placeholder={label.EnterProductName}
                                />
                                <WarningLabel show={productNameWarning} message={productNameWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="warning"
                        onClick={async (e)=>{
                            e.preventDefault()
                            this.props.onClose()
                        }}>
                        {label.Close}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={async (e)=>{
                            e.preventDefault()
                            await this.updateCustomerInfo()
                        }}>
                        {label.Save}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

CustomerInfoModal.propTypes = {
    inspectionEvent:PropTypes.object.isRequired,
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSaved:PropTypes.func.isRequired,
}
