import * as React from 'react';
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import inspectionConfirmImage from '../../assets/img/ic_inspection_confirm.png';
import PropTypes from 'prop-types';
import HorizontalLine from "../Widget/HorizontalLine";
import label from "../../message/Label";
import MyCardImage from "../Card/MyCardImage";


export default class ConfirmInspectionCloseModal extends React.Component{

    constructor(props ){
        super(props);
        this.state = {
            inspectionEvent : this.props.inspectionEvent?this.props.inspectionEvent:{},
            inspection : this.props.inspection?this.props.inspection:{},
        }
    }

    componentWillReceiveProps(props , nextContext) {
        if(this.props.modal!=props.showing){
            this.setState({
                inspectionEvent : props.inspectionEvent?props.inspectionEvent:{},
                inspection : props.inspection?props.inspection:{},
            })
        }
    }

    render(){
        let {inspectionEvent, inspection} = this.state
        let inspectionStatus = inspectionEvent.inspectionStatus?inspectionEvent.inspectionStatus:{}
        return (
            <Modal
                centered={true}
                isOpen={this.props.showing}
                backdrop={true}>
                <ModalHeader>
                    {label.ConfirmSubmission}
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody style={{textAlign:'center'}}>
                            <MyCardImage style={{width:'240px'}} src={inspectionConfirmImage}/>
                        </CardBody>
                    </Card>
                    <br/>
                    <div style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: label.YouWillCloseInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure(inspection.reference,
                            inspectionEvent.serialCode?inspectionEvent.serialCode:"<p style=\"color:red\">Not Available</p>", label.CLoseStatus)}} />
                </ModalBody>
                <HorizontalLine/>
                <ModalBody>
                    <Row>
                        <Col>
                            <Button color="warning" onClick={e=>{
                                this.props.cancelCallback()
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className={"float-right"}
                                color="primary" onClick={e=>{
                                this.props.okCallback()
                            }}>
                                {label.YesIamSure}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}
ConfirmInspectionCloseModal.propTypes = {
    inspection:PropTypes.object.isRequired,
    inspectionEvent:PropTypes.object.isRequired,
    showing:PropTypes.bool.isRequired,
    okCallback:PropTypes.func.isRequired,
    cancelCallback:PropTypes.func.isRequired
}
