import BasePage from './BasePage';
import React from 'react';
import queryString from 'query-string';
import Page from '../components/Page';
import { Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import { isEmpty, parseDate } from '../utils/Utilities';
import Global, { _POSTPONED, _CLOSED, _TAKEN, DD_MM_YYYY_HH_MM_SS, INSPECTION_STATUS_COLOR, } from '../utils/Global';
import userProfilePicture from '../assets/img/users/user_pp.png';
import { CgDanger, IoMdOpen, MdDetails, MdHistory, MdPrint, MdSend } from 'react-icons/all';
import { Button, ButtonGroup } from 'reactstrap';
import SockJsClient from 'react-stomp';
import label from '../message/Label'
import InspectionEventRectifierControllerSerialNumber
    from "../components/Widget/InspectionEventRectifierControllerSerialNumber";
import InspectionEventFormLaneWidget from "../components/Widget/InspectionEventFormLaneWidget";
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi";
import ConfirmInspectionCloseModal from "../components/modal/ConfirmInspectionCloseModal";
import ConfirmInspectionCancelModal from "../components/modal/ConfirmInspectionCancelModal";
import Typography from "../components/Typography";
import InspectionEventHistoryModal from "../components/modal/InspectionEventHistoryModal";
import ContinueInspectionConfirmModal from "../components/modal/ContinueInspectionConfirmModal";
import ModelModal from "../components/modal/ModelModal";
import { getData, getUser } from "../utils/StorageUtil";
import MyCardImage from "../components/Card/MyCardImage";
import {inspectionEventsFromNative} from "../utils/MyUtilities";
import SerialComponentInfoModal from "../components/modal/SerialComponentInfoModal";
var clone = require('safe-clone-deep');

class InspectionDetailPage extends BasePage {

    constructor(props) {
        super(props);
        let inspectionIdQuery = queryString.parse(this.props.query).id
        let state = this.state
        state.inspectionIdQuery = inspectionIdQuery
        state.inspection = {}
        state.inspectionEvent = {}
        state.inspectionEvents = []
        state.updateStatusModal = false
        state.voucherInfoModal = false
        state.stepNumber = 0
        state.userFilterExpand = true
        state.confirmInspectionCloseModal = false
        state.confirmInspectionCancelModal = false
        state.inspectionEventHistoryModal = false
        state.continueInspectionConfirmModal = false;
        state.modelModal = false
        state.serialComponentInfoModal = false
        if (isEmpty(inspectionIdQuery)) {
            this.props.history.goBack();
        }
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchInspectionDetail()
        setTimeout(() => {
            this.setState({
                userFilterExpand: false
            })
        }, 3000)
    }

    fetchInspectionDetail = () => {
        this.get(Global.API.INSPECTION, {
            params: {
                id: this.state.inspectionIdQuery
            }
        }, null, response => {
            if (response.code === 200) {
                let inspection = response.data
                let inspectionEvent = inspection.currentInspectionEvent
                this.setState({
                    inspection: inspection,
                    inspectionEvent:inspectionEvent
                }, () => {
                    this.fetchInspectionEvents(inspection.reference)
                })
            }
        }, true, true)
    }

    fetchInspectionEvents = (reference) => {
        this.get(Global.API.INSPECTION_EVENTS, {
            params: {
                reference: reference,
                rowLimit:2,
            }
        }, null, response => {
            if (response.code === 200) {
                // let inspectionEvents = response.data
                let inspectionEvents = inspectionEventsFromNative(response.data)
                this.setState({
                    inspectionEvents: inspectionEvents,
                })
            }
        }, true, false)
    }

    nextPage = () => {
        let { stepNumber, inspection, inspectionEvent,
            nextLabel, previousLabel, modeRectifierControllerSerialNumbers} = this.state
        // let currentInspectionEvent = inspection.currentInspectionEvent ? inspection.currentInspectionEvent : {}
        // let currentInspectionEvent = inspectionEvents.length>=1?inspectionEvents[0]:{}
        let currentInspectionEvent = inspectionEvent;
        let formLaneApplications = currentInspectionEvent.formLaneApplications ? currentInspectionEvent.formLaneApplications : []
        if (modeRectifierControllerSerialNumbers) {
            // currentInspectionEvent.inspectionStatus = {}
            this.setState({
                confirmInspectionModal: true,
                currentInspectionEvent: currentInspectionEvent
            })
            return;
        }

        let formLaneApplication = formLaneApplications[stepNumber];
        let formApplications = formLaneApplication.formApplications ? formLaneApplication.formApplications : []
        if (stepNumber >= formLaneApplications.length - 1) {
            this.setState({
                nextLabel: label.ConfirmSubmission,
                previousLabel: label.Previous,
                modeRectifierControllerSerialNumbers: true
            })
            return
        }
        stepNumber = stepNumber + 1;
        if (stepNumber > 0) {
            nextLabel = label.Next
            previousLabel = label.Previous
        } else {
            nextLabel = label.Next
            previousLabel = label.Cancel
        }
        this.setState({
            stepNumber: stepNumber,
            nextLabel: nextLabel,
            previousLabel: previousLabel
        })
    }

    previousPage = () => {
        let { stepNumber, nextLabel, previousLabel, modeRectifierControllerSerialNumbers } = this.state
        if (modeRectifierControllerSerialNumbers) {
            if (stepNumber > 0) {
                nextLabel = label.Next
                previousLabel = label.Previous
            } else {
                nextLabel = label.Next
                previousLabel = label.Cancel
            }
            this.setState({
                modeRectifierControllerSerialNumbers: false,
                stepNumber: stepNumber,
                nextLabel: nextLabel,
                previousLabel: previousLabel
            })
            return;
        }
        if (stepNumber <= 0) {
            return
        }
        stepNumber = stepNumber - 1;
        if (stepNumber > 0) {
            nextLabel = label.Next
            previousLabel = label.Previous
        } else {
            nextLabel = label.Next
            previousLabel = label.Cancel
        }
        this.setState({
            modeRectifierControllerSerialNumbers: false,
            stepNumber: stepNumber,
            nextLabel: nextLabel,
            previousLabel: previousLabel
        })
    }

    closeInspection = () => {
        let { inspection, inspectionEvents } = this.state
        let authorization = getData(Global.AUTHORIZATION)
        let user = getUser()

        // let currentInspectionEvent = inspection.currentInspectionEvent ? inspection.currentInspectionEvent : {}
        let currentInspectionEvent = inspectionEvents.length>=1?inspectionEvents[0]:{}
        if (currentInspectionEvent) {
            currentInspectionEvent.inspection = inspection
        }
        this.post(Global.API.INSPECTION_COMMIT + "/" + _CLOSED, clone(currentInspectionEvent), null, (response) => {
            if (response.code === 200) {
                this.fetchInspectionDetail()
                this.openSuccessDialog(label.Success, label.SuccessUpdatingStatus, () => { })
                let inspectionEvent = response.data
                let inspection = inspectionEvent.inspection
                setTimeout(() => {
                    window.open(Global.API.INSPECTION_PDF_REPORT + "/external?reference=" + inspection.reference + "&identifier=" + user.userCode, "_blank", undefined, false)
                }, 2000)
            } else {
                this.openFailedDialog(label.FailedUpdatingStatus, response.message, () => { })
            }
        }, true, false)
    }

    cancelInspection = () => {
        let { inspection, inspectionEvents } = this.state
        // let currentInspectionEvent = inspection.currentInspectionEvent ? inspection.currentInspectionEvent : {}
        let currentInspectionEvent = inspectionEvents.length>=1?inspectionEvents[0]:{}
        if (currentInspectionEvent) {
            currentInspectionEvent.inspection = inspection
        }
        this.post(Global.API.INSPECTION_COMMIT + "/" + _POSTPONED, clone(currentInspectionEvent), null, (response) => {
            if (response.code === 200) {
                this.fetchInspectionDetail()
                this.openSuccessDialog(label.Success, label.SuccessUpdatingStatus, () => {
                    this.props.history.goBack()
                })
            } else {
                this.openFailedDialog(label.FailedUpdatingStatus, response.message, () => { })
            }
        }, true, false)
    }

    updateInspection = (inspectionEvent) => {
        let { inspection } = this.state
        if (inspectionEvent) {
            inspectionEvent.inspection = inspection
        }

        this.post(Global.API.INSPECTION_COMMIT + "/" + _TAKEN, clone(inspectionEvent), null, response => {
            if (response.code === 200) {
                inspectionEvent = response.data;
                this.successToast(response.message)
                this.props.history.push('/inspection/formCheck/' + inspectionEvent.inspection.reference)
            } else {
                this.errorToast(response.message)
            }
        }, true, true)
    }


    render() {
        
        let { confirmInspectionCloseModal, confirmInspectionCancelModal,
            inspectionEventHistoryModal, continueInspectionConfirmModal, modelModal,
            inspection, inspectionEvents, serialComponentInfoModal} = this.state

        let user = getUser()

        let { modeRectifierControllerSerialNumbers, stepNumber } = this.state
        let inspectionStatus = inspection.inspectionStatus ? inspection.inspectionStatus : {}

        let currentInspectionEvent = inspection.currentInspectionEvent ? inspection.currentInspectionEvent : {}
        let currentInspectionStatus = currentInspectionEvent.inspectionStatus ? currentInspectionEvent.inspectionStatus : {}
        let currentInspectionUser = currentInspectionEvent.user ? currentInspectionEvent.user : {}

        let previousInspectionEvent = inspectionEvents.length >= 2 ? inspectionEvents[1] : {}
        if (!previousInspectionEvent.id) {
            previousInspectionEvent = inspectionEvents.length >= 1 ? inspectionEvents[0] : {}
        }
        let previousInspectionStatus = previousInspectionEvent.inspectionStatus ? previousInspectionEvent.inspectionStatus : {}
        let previousInspectionUser = previousInspectionEvent.user ? previousInspectionEvent.user : {}

        let performer = {
            "name": currentInspectionEvent.performerName,
            "role": currentInspectionEvent.performerRole,
            "imageUrl": currentInspectionEvent.performerImageUrl
        }
        let formLaneApplications = currentInspectionEvent.formLaneApplications ? currentInspectionEvent.formLaneApplications : []

        let model = inspection && inspection.model ? inspection.model : {}
        let authorization = getData(Global.AUTHORIZATION)

        // let arrInspectionEvent = inspection && inspection.inspectionEvents && Object.keys(inspection.inspectionEvents).length > 0 ? inspection.inspectionEvents : []
        let arrInspectionEvent = inspectionEvents && inspectionEvents && Object.keys(inspectionEvents).length > 0 ? inspectionEvents : []

        let lastPerformerAndStatus = null;
        let previousPerformerAndStatus = null;

        let historyInspectionEvent = arrInspectionEvent.reduce(function (arr, current) {
            if (typeof (arr) == "undefined") arr = []

            // const exist = arr.find(it => it.user.id === current.user.id);
            const exist = arr.find(it => {
                if(it && it.user && current && current.user){
                  return it.user.id === current.user.id
                }else if(it && it.admin && current && current.admin){
                  return it.admin.id === current.admin.id
                }
            });
            if (!exist) {
                arr.push(current);
            }
            return arr;
        }, [])


        if (historyInspectionEvent && Object.keys(historyInspectionEvent).length > 0) {
            lastPerformerAndStatus = {
                "name": historyInspectionEvent[0] && historyInspectionEvent[0].performerName ? historyInspectionEvent[0].performerName : historyInspectionEvent[0].user && historyInspectionEvent[0].user.fullname ? historyInspectionEvent[0].user.fullname : "",
                "role": historyInspectionEvent[0] && historyInspectionEvent[0].performerRole ? historyInspectionEvent[0].performerRole : "",
                "status": historyInspectionEvent[0] && historyInspectionEvent[0].inspectionStatus && historyInspectionEvent[0].inspectionStatus.name ? historyInspectionEvent[0].inspectionStatus.name : "ALL",
                "label": historyInspectionEvent[0] && historyInspectionEvent[0].inspectionStatus && historyInspectionEvent[0].inspectionStatus.label ? historyInspectionEvent[0].inspectionStatus.label : ""
            }

            if (historyInspectionEvent && Object.keys(historyInspectionEvent).length > 1) {
                previousPerformerAndStatus = {
                    "name": historyInspectionEvent[1] && historyInspectionEvent[1].performerName ? historyInspectionEvent[1].performerName : historyInspectionEvent[1].user && historyInspectionEvent[1].user.fullname ? historyInspectionEvent[1].user.fullname : "",
                    "role": historyInspectionEvent[1] && historyInspectionEvent[1].performerRole ? historyInspectionEvent[1].performerRole : "",
                    "status": historyInspectionEvent[1] && historyInspectionEvent[1].inspectionStatus && historyInspectionEvent[1].inspectionStatus.name ? historyInspectionEvent[1].inspectionStatus.name : "ALL",
                    "label": historyInspectionEvent[1] && historyInspectionEvent[1].inspectionStatus && historyInspectionEvent[1].inspectionStatus.label ? historyInspectionEvent[1].inspectionStatus.label : ""
                }
            }
        }

        return (
            <Page
                title={label.InspectionDetail}
                breadcrumbs={[{ name: label.InspectionDetail, active: true }]}>
                {super.render()}
                <ConfirmInspectionCloseModal
                    inspection={inspection}
                    inspectionEvent={currentInspectionEvent}
                    showing={confirmInspectionCloseModal}
                    okCallback={() => {
                        this.setState({
                            confirmInspectionCloseModal: false
                        }, () => {
                            this.closeInspection()
                        })
                    }}
                    cancelCallback={() => {
                        this.setState({ confirmInspectionCloseModal: false })
                    }}
                />
                <ConfirmInspectionCancelModal
                    inspection={inspection}
                    inspectionEvent={currentInspectionEvent}
                    showing={confirmInspectionCancelModal}
                    okCallback={() => {
                        this.setState({
                            confirmInspectionCancelModal: false
                        }, () => {
                            this.cancelInspection()
                        })
                    }}
                    cancelCallback={() => {
                        this.setState({ confirmInspectionCancelModal: false })
                    }}
                />
                <InspectionEventHistoryModal
                    showing={inspectionEventHistoryModal} onClose={() => {
                        this.setState({
                            inspectionEventHistoryModal: false
                        })
                    }} inspection={inspection} />

                <ContinueInspectionConfirmModal
                    show={continueInspectionConfirmModal}
                    inspection={inspection}
                    onClickOk={() => {
                        this.setState({
                            continueInspectionConfirmModal: false,
                        }, () => {
                            this.updateInspection(currentInspectionEvent)
                        })
                    }}
                    onClickCancel={() => {
                        this.setState({
                            inspectionEvent: {},
                            continueInspectionConfirmModal: false,
                        })
                    }}
                />
                <ModelModal
                    show={modelModal}
                    model={model}
                    onClickOk={() => {
                        this.setState({ modelModal: false })
                    }} />
                <SerialComponentInfoModal
                    inspection={inspection}
                    showing={serialComponentInfoModal}
                    onClose={()=>{
                        this.setState({serialComponentInfoModal:false})
                    }} />
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader
                                style={{ cursor: 'pointer' }}
                                onClick={event => {
                                    this.setState({
                                        userFilterExpand: !this.state.userFilterExpand
                                    })
                                }}>
                                {
                                    (this.state.userFilterExpand) ? (<FiArrowUpCircle />) : ((<FiArrowDownCircle />))
                                }&nbsp;{label.InspectionDetail}&nbsp;({label.ClickToExpandMoreInformation})
                            </CardHeader>
                            <CardBody>
                                <Collapse isOpen={this.state.userFilterExpand}>
                                    <Row>
                                        <Col md={5}>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>{label.PerformedBy}</CardHeader>
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <MyCardImage
                                                                        top
                                                                        src={performer.imageUrl ? performer.imageUrl : userProfilePicture}
                                                                        onError={(elm) => this.defaultImage(elm, userProfilePicture)}
                                                                        onClick={() => {
                                                                            if (performer.imageUrl) {
                                                                                this.openLightBoxSingleImage(performer.imageUrl)
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col md={8}>
                                                                    {lastPerformerAndStatus ? (
                                                                        <div>
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    {label.LastPerformer}
                                                                                </Col>
                                                                                <Col md={8}>
                                                                                    <span>
                                                                                        {
                                                                                            (currentInspectionEvent.user && currentInspectionEvent.user.id) ? (
                                                                                                <a href={'/userDetail?id=' + currentInspectionEvent.user.id}>{lastPerformerAndStatus.name} -  {lastPerformerAndStatus.role}</a>
                                                                                            ) :
                                                                                                (<span>{lastPerformerAndStatus.name} -  {lastPerformerAndStatus.role}</span>)
                                                                                        }
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    {label.LastStatus}
                                                                                </Col>
                                                                                <Col md={8}>
                                                                                    <span
                                                                                        style={
                                                                                            {
                                                                                                background: INSPECTION_STATUS_COLOR[lastPerformerAndStatus.status],
                                                                                                paddingLeft: '8px', paddingRight: '8px', paddingTop: '4px', paddingBottom: '4px', color: 'white',
                                                                                                fontWeight: 'bold', borderRadius: '8px'
                                                                                            }
                                                                                        }>
                                                                                        {lastPerformerAndStatus.label}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    ) : null}

                                                                    {previousPerformerAndStatus ?
                                                                        (<div>
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    {label.PreviousPerformer}
                                                                                </Col>
                                                                                <Col md={8}>
                                                                                    <span>
                                                                                        {
                                                                                            (currentInspectionEvent.user && currentInspectionEvent.user.id) ? (
                                                                                                <a href={'/userDetail?id=' + currentInspectionEvent.user.id}>{previousPerformerAndStatus.name} -  {previousPerformerAndStatus.role}</a>
                                                                                            ) :
                                                                                                (<span>{previousPerformerAndStatus.name} -  {previousPerformerAndStatus.role}</span>)
                                                                                        }
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    {label.PreviousStatus}
                                                                                </Col>
                                                                                <Col md={8}>
                                                                                    <span
                                                                                        style={
                                                                                            {
                                                                                                background: INSPECTION_STATUS_COLOR[previousPerformerAndStatus.status],
                                                                                                paddingLeft: '8px', paddingRight: '8px', paddingTop: '4px', paddingBottom: '4px', color: 'white',
                                                                                                fontWeight: 'bold', borderRadius: '8px'
                                                                                            }
                                                                                        }>
                                                                                        {previousPerformerAndStatus.label}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>) : null}


                                                                    <Row>
                                                                        <Col md={4}>
                                                                            {label.LastModification}
                                                                        </Col>
                                                                        <Col md={8}>
                                                                            <span>
                                                                                {parseDate(currentInspectionEvent.created, DD_MM_YYYY_HH_MM_SS)}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>


                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <Typography style={{ textAlign: 'center', fontSize: '32px' }} type={"text-light bg-dark"}>{inspection.reference}</Typography>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Typography
                                                                        block
                                                                        type="text-white bg-primary"
                                                                        style={{ textAlign: 'center', fontSize: '32px', cursor: 'pointer' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault()
                                                                            this.setState({
                                                                                inspectionEventHistoryModal: true
                                                                            })
                                                                        }}
                                                                    >
                                                                        {label.History}&nbsp;&nbsp;&nbsp;<MdHistory />
                                                                    </Typography>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={7}>
                                            <Card>
                                                <CardHeader>{label.Model}</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="systemType">{label.SystemType}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="systemType"
                                                                    readOnly={true}
                                                                    value={model.systemType}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label for="modelName">{label.ModelName}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="modelName"
                                                                    readOnly={true}
                                                                    value={model.modelName}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={1}>
                                                            <FormGroup>
                                                                <Label>&nbsp;</Label>
                                                                <Button
                                                                    className={"form-control"}
                                                                    onClick={() => {
                                                                        this.setState({ modelModal: true })
                                                                    }}
                                                                >
                                                                    <MdDetails />
                                                                </Button>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Label for="serialComponent">&nbsp;&nbsp;</Label>
                                                            <Button
                                                                name="serialComponent"
                                                                onClick={()=>{
                                                                    this.setState({serialComponentInfoModal:true})
                                                                }}
                                                                block>
                                                                {label.SerialComponentList}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="description">{label.SerialCode}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="serialCode"
                                                                    readOnly={true}
                                                                    value={inspection.serialCode}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="customerOrderNo">{label.CustomerOrderNo}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="customerOrderNo"
                                                                    readOnly={true}
                                                                    value={inspection.customerOrderNo}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label>{label.CurrentInspectionStatus}</Label>
                                                                <Input
                                                                    className={"inspection-status-" + (inspectionStatus.name ? inspectionStatus.name.toLowerCase() : "")}
                                                                    type="text"
                                                                    name="inspectionStatus"
                                                                    readOnly={true}
                                                                    value={inspectionStatus.label}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="description">{label.ProductSKU}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="productSKU"
                                                                    readOnly={true}
                                                                    value={inspection.productSKU}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label for="customerOrderNo">{label.ProductName}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="productName"
                                                                    readOnly={true}
                                                                    value={inspection.productName}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label>{label.Client}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="client"
                                                                    readOnly={true}
                                                                    value={inspection.client}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label>{label.InspectionNote}</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="inspectionNote"
                                                                    readOnly={true}
                                                                    value={currentInspectionEvent.inspectionNote}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label for="description">{label.Description}</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="description"
                                                                    readOnly={true}
                                                                    value={model.description}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Collapse>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                <ButtonGroup className="float-right">
                                                    {
                                                        (currentInspectionEvent.canReprint) && (
                                                            <>
                                                                <a
                                                                    target="_blank"
                                                                    href={Global.API.INSPECTION_PDF_REPORT
                                                                        + "/external?reference=" + inspection.reference + "&identifier=" + user.userCode}>
                                                                    <Button color={"primary"}>
                                                                        {label.ExternalPrint}&nbsp;<MdPrint />
                                                                    </Button>
                                                                </a>
                                                                &nbsp;
                                                                <a
                                                                    target="_blank"
                                                                    href={Global.API.INSPECTION_PDF_REPORT
                                                                        + "/internal?reference=" + inspection.reference + "&identifier=" + user.userCode}>
                                                                    <Button color={"purple"}>
                                                                        {label.InternalPrint}&nbsp;<MdPrint />
                                                                    </Button>
                                                                </a>
                                                                &nbsp;
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (currentInspectionEvent.canClose) && (
                                                            <>
                                                                <Button
                                                                    color={"primary"}
                                                                    onClick={() => {
                                                                        this.setState({ confirmInspectionCloseModal: true })
                                                                    }}>
                                                                    {label.CloseStatusAndPrint}&nbsp;<MdPrint />
                                                                </Button>
                                                                &nbsp;
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (currentInspectionEvent.canCancel) && (
                                                            <>
                                                                <Button
                                                                    style={{ marginRight: '6px' }}
                                                                    color={"danger"}
                                                                    onClick={() => {
                                                                        this.setState({ confirmInspectionCancelModal: true })
                                                                    }}>
                                                                    {label.PostponeInspection}&nbsp;<CgDanger />
                                                                </Button>
                                                                &nbsp;
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (currentInspectionEvent.canContinue) && (
                                                            <>
                                                                <Button
                                                                    style={{ marginRight: '6px' }}
                                                                    color={"primary"}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            continueInspectionConfirmModal: true
                                                                        })
                                                                    }}
                                                                >
                                                                    {label.ContinueInspection}&nbsp;<MdSend />
                                                                </Button>
                                                                &nbsp;
                                                            </>
                                                        )
                                                    }
                                                </ButtonGroup>

                                                <Button
                                                    style={{ marginRight: '6px' }}
                                                    color={stepNumber == 0 && !modeRectifierControllerSerialNumbers ? "disabled" : "warning"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.previousPage()
                                                    }}>
                                                    {label.PagePrevious}
                                                </Button>
                                                <Button
                                                    color={modeRectifierControllerSerialNumbers ? "disabled" : "primary"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.nextPage()
                                                    }}>
                                                    {label.PageNext}
                                                </Button>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        {
                                                            modeRectifierControllerSerialNumbers ?
                                                                (
                                                                    <InspectionEventRectifierControllerSerialNumber
                                                                        inspection={inspection}
                                                                        inspectionEvent={currentInspectionEvent}
                                                                        rectifierControllerSerialNumbers={currentInspectionEvent.rectifierControllerSerialNumbers} />
                                                                )
                                                                :
                                                                (
                                                                    <InspectionEventFormLaneWidget
                                                                        inspection={inspection}
                                                                        inspectionEvent={currentInspectionEvent}
                                                                        stepFormLaneId={formLaneApplications[stepNumber] ? formLaneApplications[stepNumber].laneId : 0}
                                                                        formLaneApplication={formLaneApplications[stepNumber] ? formLaneApplications[stepNumber] : {}} />
                                                                )
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <SockJsClient
                    url={Global.BASE_URL}
                    topics={[Global.API.LISTEN_INSPECTION + "/" + inspection.id]}
                    getRetryInterval={()=>{return 5}}
                    heartbeat={60000}
                    onMessage={(message) => {
                        this.successToast(message)
                        this.fetchInspectionDetail()
                    }}
                    ref={(client) => { this.clientRef = client }} />
            </Page>
        );
    }
}

export default Object.assign(InspectionDetailPage, { ENDPOINT: "/inspectionDetail" })