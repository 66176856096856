import {Content, Footer, Header, Sidebar} from '../../components/Layout';
import React from 'react';
import NotificationSystem from 'react-notification-system';
import {NOTIFICATION_SYSTEM_STYLE} from '../../utils/constants';
import {AuthConsumer} from '../../utils/AuthContext';
import {isMobile} from 'react-device-detect';

class MainLayout extends React.Component {


  static isSidebarOpen() {
    return document.querySelector('.cr-sidebar').classList.contains('cr-sidebar--open');
  }

  componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (MainLayout.isSidebarOpen() && (this.props.breakpoint === 'xs' || this.props.breakpoint === 'sm' || this.props.breakpoint === 'md')) {
        this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
        return this.openSidebar('open');
      default:
        if(isMobile){
          return this.openSidebar('close');
        }else{
          return this.openSidebar('open');
        }
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }

  render() {
    const { children } = this.props;
    return (
        <AuthConsumer>
          {
            ({admin})=>(
                <main className="cr-app">
                  <Sidebar />
                  <Content fluid onClick={this.handleContentClick}>
                    <Header {...this.props} />
                    {children}
                    <Footer />
                  </Content>

                  <NotificationSystem
                      dismissible={false}
                      ref={notificationSystem =>
                          (this.notificationSystem = notificationSystem)
                      }
                      style={NOTIFICATION_SYSTEM_STYLE}
                  />
                </main>
            )
          }
        </AuthConsumer>
    );
  }
}

export default MainLayout;
